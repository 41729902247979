<!-- Localized -->
<template>
  <div class="scrollbar w-full relative scroll-bar flex flex-col filters-container">
    <div class="w-full overflow-y-auto scroll-bar relative pb-5" style="height: 90vh">
      <AccordionCard :isFilter="true">
        <template v-slot:title>
          <span class="f-title flex items-center filter-color">
            <PeopleSVG class="mx-2" /> {{ $t('components.light_ray.people_n', { value: peopleCheckedCount }) }}
          </span>
        </template>
        <template v-slot:titleCheckbox>
          <div class="ml-4">
            <input type="checkbox" id="checkboxInput" @change="
              $emit('handleAllCheckboxChange', $event, 'persons', true)
              " :checked="isAllPeopleChecked" />
            <label class="mx-1">{{ $t('components.light_ray.select_all') }}</label>
          </div>
        </template>
        <template v-slot:content>
          <div class="p-2">
            <div class="form-control">
              <Input class="w-70" v-model="filterData.personsTerm"
                @input="$emit('handleSearchboxChange', $event, 'namesTerm')" icon="search" :placeholder="$t('components.light_ray.search_people')" />
            </div>
            <div v-if="loading" class="flex justify-center items-center pt-3">
              <Loader />
            </div>
            <div v-else class="grid grid-cols-2 gap-4 py-2 pl-2">
              <div v-for="person in getPeople" :key="person.name">
                <input type="checkbox" @change="
                  $emit('handleCheckboxChange', $event, person.key, 'persons')
                  " :id="person.key" v-model="person.checked" class="" />
                <label class="filter-item-label mx-2">{{ person.key }}</label>
              </div>
            </div>
            <div @click="openMenu('person')">
              <!-- v-if="seeMorePeople" -->
              <span  @click="$emit('handleSeeMore', true, 'persons')"
                class="f-title cursor-pointer pl-2">{{ $t('components.light_ray.load_more') }}</span>
            </div>
            <!-- <span v-if="filterData.seeMorePeople" @click="$emit('handleSeeMore', false, 'seeMorePeople')"
                            class="f-title cursor-pointer pl-2">Show Less</span> -->
            <!--<Popup :showMenu="showMenu" @closeMenu="closeMenu()" :filterData="filterData" :moreFilterData="loadMoreData.persons"/>-->
            <Popup v-if="showMenu[0].isOpen" :isOpen="showMenu[0].isOpen" position="right" @click="closeMenu"
              class="popupFilter">
              <div>
                <nav class="h-16 border-b-2 p-3 w-fit flex justify-between static z-50 navheader">
                  <div class="">
                    <span class="absolute top-6 left-5"><svg xmlns="http://www.w3.org/2000/svg" height="18" width="18"
                        viewBox="0 0 512 512">
                        <path fill="#910000"
                          d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                      </svg></span>
                    <input class="p-2 border-2 rounded-xl input" type="text" :placeholder="$t('components.light_ray.search_peoples')"
                      v-model="filterData.personsTerm" @input="
                        $emit('handleSearchboxChange', $event, 'namesTerm')
                        " />
                  </div>
                  <div>
                    <div class="flex">
                      <button class="m-1 text-stone-200 cursor-pointer pt-1 active" @click="
                        $emit('handleSearchboxChange', letter, 'namesTerm')
                        " v-for="letter in alphabet" :key="letter">
                        {{ letter }}
                      </button>
                    </div>
                    <!--  <div class="flex">
                                    <div class="m-1 text-stone-200 cursor-pointer mb-3" v-for="digit in this.digits" :key="digit">{{digit}}</div>
                                </div>-->
                  </div>
                  <div class="flex">
                    <div class="" v-bind:style="{ marginTop: '11px' }">
                      <input type="checkbox" id="checkboxInput" @change="
                        $emit('handleAllCheckboxChange', $event, 'persons')
                        " :checked="isAllPeopleChecked" />
                      <label class="mx-2">{{ $t('components.light_ray.select_all') }}</label>
                    </div>
                    <div class="mx-2 cursor-pointer float-right pt-2" @click="closeMenu('person', 1)">
                      <Cross />
                    </div>
                  </div>
                </nav>
                <div class="w-fit overflow-x-auto">
                  <!--<div v-for="(row, index) in dividedData" :key="index" class="grid-row">
                            <div v-for="(item, idx) in row" :key="idx" class="grid-item">
                                Display your item data here 
                                {{ item.key }}
                                </div>
                            </div>-->
                  <div v-if="loading" class="flex justify-center items-center h-64">
                    <Loader />
                  </div>
                  <div v-else-if="filterData.persons.length > 0 && !loading" class="w-max overflow-x-auto">
                    <table>
                      <thead>
                        <tr class="h-4.5" v-for="(row, index) in dividedData" :key="index">
                          <!-- <td v-if="index==0" class="m-2 p-2">
                                        <div class="ms-2">
                                            <input type="checkbox" id="checkboxInput"
                                                @change="$emit('handleAllCheckboxChange', $event, 'persons')" :checked="isAllPeopleChecked" />
                                            <label class="mx-2">Select all</label>
                                        </div>
                                    </td>-->
                          <td class="personName truncate ..." :title="item?.key" v-for="(item, idx) in row" :key="idx">
                            <input class="m-2" type="checkbox" @change="
                              $emit(
                                'handleCheckboxChange',
                                $event,
                                item.key,
                                'persons'
                              )
                              " :id="item.key" v-model="item.checked" />{{ item.key }}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div v-else class="w-max m-auto pt-3 overflow-x-auto">
                    <p>{{ $t('components.light_ray.no_data_found') }}</p>
                  </div>
                </div>
                <footer class="h-16 p-1 w-full flex justify-between absolute bottom-0 z-50 navheader">
                  <div class="flex w-full h-16 justify-end">
                    <button
                      class="bg-transparent text-red-800 font-semibold w-36 border border-red-800 text-white font-bold py-3 px-5 m-2 rounded-md"
                      @click="$emit('resetFilter', 'namesTerm', 'personsTerm')">
                      {{ $t('actions.reset') }}</button>
                    <button class="bg-red-800 hover:bg-red-400 text-white w-40 font-bold py-3 px-5 m-2 rounded-md"
                      @click="saveClose('person')">
                      {{ $t('actions.save') }}
                    </button>
                  </div>
                </footer>
              </div>
            </Popup>
          </div>
        </template>
      </AccordionCard>
      <!-- Company -->
      <AccordionCard :isFilter="true">
        <template v-slot:title>
          <span class="f-title flex items-center filter-color">
            <CompanySVG fill="#910000" class="mx-2" />
            {{ $t('components.light_ray.company_n', { value: companyCheckedCount }) }}
          </span>
          <div class="ml-4">
            <input type="checkbox" id="checkboxInput" :checked="isAllCompanyChecked" @change="
              $emit('handleAllCheckboxChange', $event, 'organisations', true)
              " />
            <label class="mx-1">{{ $t('components.light_ray.select_all') }}</label>
          </div>
        </template>
        <template v-slot:content>
          <div class="p-2">
            <div class="form-control">
              <Input class="w-70" icon="search" v-model="filterData.organisationsTerm" id="companyTerm" @input="
                $emit('handleSearchboxChange', $event, 'organisationsTerm')
                " :placeholder="$t('components.light_ray.search_company')" />
            </div>
            <div v-if="loading" class="flex justify-center items-center pt-3">
              <Loader />
            </div>
            <div v-else class="grid grid-cols-2 gap-4 py-2 pl-2">
              <div c v-for="company in getCompanies" :key="company.key">
                <input type="checkbox" @change="
                  $emit(
                    'handleCheckboxChange',
                    $event,
                    company.key,
                    'organisations'
                  )
                  " :id="company.key" v-model="company.checked" class="" />
                <label class="filter-item-label mx-2">{{ company.key }} ({{ company.count }})</label>
              </div>
            </div>
            <div @click="openMenu('company')">
              <!-- v-if="seeMoreOrganisations" -->
              <span  @click="$emit('handleSeeMore', true, 'organisations')"
                class="f-title cursor-pointer pl-2">{{ $t('components.light_ray.load_more') }}</span>
            </div>
            <!-- <span v-if="filterData.seeMoreCompanies" @click="$emit('handleSeeMore', false, 'seeMoreCompanies')"
                            class="f-title cursor-pointer pl-2">Show Less</span> -->
            <!--<Popup :showMenu="showMenu" @closeMenu="closeMenu()" :filterData="filterData" :moreFilterData="loadMoreData.organisations"/>-->
            <Popup v-if="showMenu[1].isOpen" :isOpen="showMenu[1].isOpen" position="right" @click="closeMenu"
              class="popupFilter">
              <div>
                <nav class="h-16 border-b-2 p-3 w-fit flex justify-between static z-50 navheader">
                  <div class="">
                    <span class="absolute top-6 left-5"><svg xmlns="http://www.w3.org/2000/svg" height="18" width="18"
                        viewBox="0 0 512 512">
                        <path fill="#910000"
                          d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                      </svg></span>
                    <input class="p-2 border-2 rounded-xl input" type="text" :placeholder="$t('components.light_ray.search_company')"
                      v-model="filterData.organisationsTerm" id="companyTerm" @input="
                        $emit(
                          'handleSearchboxChange',
                          filterData.organisationsTerm,
                          'organisationsTerm'
                        )
                        " />
                  </div>
                  <div>
                    <div class="flex">
                      <button class="m-1 text-stone-200 cursor-pointer pt-1 active" @click="
                        $emit(
                          'handleSearchboxChange',
                          letter,
                          'organisationsTerm'
                        )
                        " v-for="letter in alphabet" :key="letter">
                        {{ letter }}
                      </button>
                    </div>
                    <!--  <div class="flex">
                                    <div class="m-1 text-stone-200 cursor-pointer mb-3" v-for="digit in this.digits" :key="digit">{{digit}}</div>
                                </div>-->
                  </div>
                  <div class="flex">
                    <div v-bind:style="{ marginTop: '11px' }">
                      <input type="checkbox" id="checkboxInput" :checked="isAllCompanyChecked" @change="
                        $emit(
                          'handleAllCheckboxChange',
                          $event,
                          'organisations'
                        )
                        " />
                      <label class="mx-2">{{ $t('components.light_ray.select_all') }}</label>
                    </div>
                    <div class="mx-2 cursor-pointer float-right pt-2" @click="closeMenu('company', 2)">
                      <Cross />
                    </div>
                  </div>
                </nav>
                <div class="w-fit overflow-x-auto">
                  <!--<div v-for="(row, index) in dividedData" :key="index" class="grid-row">
                            <div v-for="(item, idx) in row" :key="idx" class="grid-item">
                                Display your item data here 
                                {{ item.key }}
                                </div>
                            </div>-->
                  <div v-if="loading" class="flex justify-center items-center h-64">
                    <Loader />
                  </div>
                  <div v-else-if="filterData.organisations.length > 0 && !loading" class="w-max overflow-x-auto">
                    <table>
                      <thead>
                        <tr class="h-4.5" v-for="(row, index) in dividedOrgData" :key="index">
                          <td class="personName truncate ..." :title="getTitle(item)" v-for="(item, idx) in row" :key="idx">
                            <input class="m-2" type="checkbox" @change="
                              $emit(
                                'handleCheckboxChange',
                                $event,
                                item.key,
                                'organisations'
                              )
                              " :id="item.key" v-model="item.checked" />{{ item.key }} ({{ item.count }})
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div v-else class="w-max m-auto pt-3 overflow-x-auto">
                    <p>{{ $t('components.light_ray.no_data_found') }}</p>
                  </div>
                </div>
                <footer class="h-16 p-1 w-full flex justify-between absolute bottom-0 z-50 navheader">
                  <div class="flex w-full h-16 justify-end">
                    <button
                      class="bg-transparent text-red-800 font-semibold w-36 border border-red-800 text-white font-bold py-3 px-5 m-2 rounded-md"
                      @click="$emit('resetFilter', 'organisationsTerm')">
                      {{ $t('actions.reset') }}
                    </button>
                    <button class="bg-red-800 hover:bg-red-400 text-white w-40 font-bold py-3 px-5 m-2 rounded-md"
                      @click="saveClose('company')">
                      {{ $t('actions.save') }}
                    </button>
                  </div>
                </footer>
              </div>
            </Popup>
          </div>
        </template>
      </AccordionCard>
      <!-- Status -->
      <!-- <AccordionCard :isFilter="true">
        <template v-slot:title>
          <span class="f-title flex items-center filter-color">
            <StatusSVG class="mx-2" /> Status {{ `(${statusCheckedCount})` }}
          </span>
          <div class="ml-4">
            <input type="checkbox" id="checkboxInput" @change="$emit('handleAllCheckboxChange', $event, 'status')"
              :checked="isAllStatusChecked" />
            <label class="mx-1">Select all</label>
          </div>
        </template>
        <template v-slot:content>
          <div class="p-2">
            <div class="form-control">
              <Input class="w-70" icon="search" v-model="filterData.statusTerm"
                @input="$emit('handleSearchboxChange', $event, 'statusTerm')" placeholder="Search status" />
            </div>
            <div class="grid grid-cols-2 gap-4 py-2 pl-2">
              <div c v-for="status in getStatus" :key="status.key">
                <input type="checkbox" @change="
                  $emit('handleCheckboxChange', $event, status.key, 'status')
                  " :id="status.key" v-model="status.checked" class="" />
                <label class="filter-item-label mx-2">{{ status?.key || "Unknown" }} ({{ status.count }})</label>
              </div>
            </div>
          </div>
        </template>
      </AccordionCard> -->
      <!-- Risks -->
      <AccordionCard :isFilter="true">
        <template v-slot:title>
          <span class="f-title flex items-center filter-color">
            <RiskSVG class="mx-2" /> {{ $t('components.light_ray.risks_n', { value: riskCheckedCount }) }}
          </span>
          <div class="ml-4">
            <input type="checkbox" id="checkboxInput" @change="$emit('handleAllCheckboxChange', $event, 'risks', true)"
              :checked="isAllRisksChecked" />
            <label class="mx-1">{{ $t('components.light_ray.select_all') }}</label>
          </div>
        </template>
        <template v-slot:content>
          <div class="p-2">
            <div class="form-control">
              <Input class="w-70" icon="search" id="risksTerm" @input="$emit('handleSearchboxChange', $event, 'riskTerm')"
                v-model="filterData.riskssTerm" :placeholder="$t('components.light_ray.search_risk')" />
            </div>
            <div v-if="loading" class="flex justify-center items-center pt-3">
              <Loader />
            </div>
            <div v-else class="grid grid-cols-2 gap-4 py-2 pl-2">
              <div c v-for="risk in getRisks" :key="risk.key">
                <input type="checkbox" @change="
                  $emit('handleCheckboxChange', $event, risk.key, 'risks')
                  " :id="risk.key" v-model="risk.checked" class="" />
                <label class="filter-item-label mx-2">{{ risk.key }} ({{ risk.count }})</label>
              </div>
            </div>
            <div @click="openMenu('risk')">
              <!-- v-if="seeMoreRisks" -->
              <span  @click="$emit('handleSeeMore', true, 'riskss')"
                class="f-title cursor-pointer pl-2">{{ $t('components.light_ray.load_more') }}</span>
            </div>
            <!-- <span v-if="filterData.seeMoreRisks" @click="$emit('handleSeeMore', false, 'seeMoreRisks')"
                            class="f-title cursor-pointer pl-2">Show Less</span> -->
            <Popup v-if="showMenu[2].isOpen" :isOpen="showMenu[2].isOpen" position="right" @click="closeMenu"
              class="popupFilter">
              <div>
                <nav class="h-16 border-b-2 p-3 w-fit flex justify-between static z-50 navheader">
                  <div class="">
                    <span class="absolute top-6 left-5"><svg xmlns="http://www.w3.org/2000/svg" height="18" width="18"
                        viewBox="0 0 512 512">
                        <path fill="#910000"
                          d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                      </svg></span>
                    <input class="p-2 border-2 rounded-xl input" type="text" :placeholder="$t('components.light_ray.search_risk')" @input="
                      $emit('handleSearchboxChange', $event, 'riskTerm')
                      " v-model="filterData.riskssTerm" />
                  </div>
                  <div>
                    <div class="flex">
                      <button class="m-1 text-stone-200 cursor-pointer pt-1 active" @click="
                        $emit(
                          'handleSearchboxChange',
                          letter,
                          'riskTerm',
                          true
                        )
                        " v-for="letter in alphabet" :key="letter">
                        {{ letter }}
                      </button>
                    </div>
                    <!--  <div class="flex">
                                    <div class="m-1 text-stone-200 cursor-pointer mb-3" v-for="digit in this.digits" :key="digit">{{digit}}</div>
                                </div>-->
                  </div>
                  <div class="flex">
                    <div class="" v-bind:style="{ marginTop: '11px' }">
                      <input type="checkbox" id="checkboxInput" @change="
                        $emit('handleAllCheckboxChange', $event, 'risks')
                        " :checked="isAllRisksChecked" />
                      <label class="mx-2">{{ $t('components.light_ray.select_all') }}</label>
                    </div>
                    <div class="mx-2 cursor-pointer float-right pt-2" @click="closeMenu('risk')">
                      <Cross />
                    </div>
                  </div>
                </nav>
                <div class="w-fit overflow-x-auto">
                  <!--<div v-for="(row, index) in dividedData" :key="index" class="grid-row">
                            <div v-for="(item, idx) in row" :key="idx" class="grid-item">
                                Display your item data here 
                                {{ item.key }}
                                </div>
                            </div>-->
                  <div v-if="loading" class="flex justify-center items-center h-64">
                    <Loader />
                  </div>
                  <div v-else-if="filterData.risks.length > 0 && !loading" class="w-max overflow-x-auto">
                    <table>
                      <thead>
                        <tr class="h-4.5" v-for="(row, index) in dividedRiskData" :key="index">
                          <td class="personName truncate ..." :title="getTitle(item)" v-for="(item, idx) in row" :key="idx">
                            <input class="m-2" type="checkbox" @change="
                              $emit(
                                'handleCheckboxChange',
                                $event,
                                item.key,
                                'risks'
                              )
                              " :id="item.key" v-model="item.checked" />{{ item.key }} ({{ item.count }})
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div v-else class="w-max m-auto pt-3 overflow-x-auto">
                    <p>{{ $t('components.light_ray.no_data_found') }}</p>
                  </div>
                </div>
                <footer class="h-16 p-1 w-full flex justify-between absolute bottom-0 z-50 navheader">
                  <div class="flex w-full h-16 justify-end">
                    <button
                      class="bg-transparent text-red-800 font-semibold w-36 border border-red-800 text-white font-bold py-3 px-5 m-2 rounded-md"
                      @click="$emit('resetFilter', 'riskTerm', 'riskssTerm')">
                      {{ $t('actions.reset') }}
                    </button>
                    <button class="bg-red-800 hover:bg-red-400 text-white w-40 font-bold py-3 px-5 m-2 rounded-md"
                      @click="saveClose('risk')">
                      {{ $t('actions.save') }}
                    </button>
                  </div>
                </footer>
              </div>
            </Popup>
          </div>
        </template>
      </AccordionCard>
      <!-- Locations -->
      <AccordionCard :isFilter="true">
        <template v-slot:title>
          <span class="f-title flex items-center filter-color">
            <LocationSVG class="mx-2" />
            {{ $t('components.light_ray.locations_n', { value: locationCheckedCount }) }}
          </span>
          <div class="ml-4">
            <input type="checkbox" id="checkboxInput" @change="
              $emit('handleAllCheckboxChange', $event, 'locations', true)
              " :checked="isAllLocationChecked" />
            <label class="mx-1">{{ $t('components.light_ray.select_all') }}</label>
          </div>
        </template>
        <template v-slot:content>
          <!-- <div id="map" class="h-40"></div> -->
          <div class="p-2">
            <div class="form-control">
              <Input class="w-70" icon="search" id="locationTerm" v-model="filterData.locationsTerm"
                @input="$emit('handleSearchboxChange', $event, 'locationTerm')" :placeholder="$t('components.light_ray.search_locations')" />
            </div>
            <div v-if="loading" class="flex justify-center items-center pt-3">
              <Loader />
            </div>
            <div v-else class="grid grid-cols-2 gap-4 py-2 pl-2">
              <div c v-for="locations in getLocation" :key="locations.key">
                <input type="checkbox" @change="
                  $emit(
                    'handleCheckboxChange',
                    $event,
                    locations.key,
                    'locations',
                    true
                  )
                  " :id="locations.key" v-model="locations.checked" class="" />
                <label class="filter-item-label mx-2">{{
                  locations?.key || "Unknown"
                }}</label>
              </div>
            </div>
            <div @click="openMenu('location')">
              <!-- v-if="seeMoreLocations" -->
              <span  @click="$emit('handleSeeMore', true, 'locations')"
                class="f-title cursor-pointer pl-2">{{ $t('components.light_ray.load_more') }}</span>
            </div>
            <!-- <span v-if="filterData.seeMoreLocation" @click="$emit('handleSeeMore', false, 'seeMoreLocation')"
                            class="f-title cursor-pointer pl-2">Show Less</span> -->
            <Popup v-if="showMenu[3].isOpen" :isOpen="showMenu[3].isOpen" position="right" @click="closeMenu"
              class="popupFilter">
              <div>
                <nav class="h-16 border-b-2 p-3 w-fit flex justify-between static z-50 navheader">
                  <div class="">
                    <span class="absolute top-6 left-5"><svg xmlns="http://www.w3.org/2000/svg" height="18" width="18"
                        viewBox="0 0 512 512">
                        <path fill="#910000"
                          d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
                      </svg></span>
                    <input class="p-2 border-2 rounded-xl input" type="text" v-model="filterData.locationsTerm" @input="
                      $emit('handleSearchboxChange', $event, 'locationTerm')
                      " :placeholder="$t('components.light_ray.search_locations')" />
                  </div>
                  <div>
                    <div class="flex">
                      <button class="m-1 text-stone-200 cursor-pointer pt-1 active" @click="
                        $emit(
                          'handleSearchboxChange',
                          letter,
                          'locationTerm',
                          true
                        )
                        " v-for="letter in alphabet" :key="letter">
                        {{ letter }}
                      </button>
                    </div>
                    <!--  <div class="flex">
                                    <div class="m-1 text-stone-200 cursor-pointer mb-3" v-for="digit in this.digits" :key="digit">{{digit}}</div>
                                </div>-->
                  </div>
                  <div class="flex">
                    <div class="" v-bind:style="{ marginTop: '11px' }">
                      <input type="checkbox" id="checkboxInput" @change="
                        $emit('handleAllCheckboxChange', $event, 'locations')
                        " :checked="isAllLocationChecked" />
                      <label class="mx-2">{{ $t('components.light_ray.select_all') }}</label>
                    </div>
                    <div class="mx-2 cursor-pointer float-right pt-2" @click="closeMenu('location')">
                      <Cross />
                    </div>
                  </div>
                </nav>
                <div class="w-fit overflow-x-auto">
                  <!--<div v-for="(row, index) in dividedData" :key="index" class="grid-row">
                            <div v-for="(item, idx) in row" :key="idx" class="grid-item">
                                Display your item data here 
                                {{ item.key }}
                                </div>
                            </div>-->
                  <div v-if="loading" class="flex justify-center items-center h-64">
                    <Loader />
                  </div>
                  <div v-else-if="filterData.locations.length > 0 && !loading" class="w-max overflow-x-auto">
                    <table>
                      <thead>
                        <tr class="h-4.5" v-for="(row, index) in dividedLocData" :key="index">
                          <td class="personName truncate ..." :title="item?.key" v-for="(item, idx) in row" :key="idx">
                            <input class="m-2" type="checkbox" @change="
                              $emit(
                                'handleCheckboxChange',
                                $event,
                                item.key,
                                'locations'
                              )
                              " :id="item.key" v-model="item.checked" />{{ item.key }}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                  <div v-else class="w-max pt-3 m-auto overflow-x-auto">
                    <p>{{ $t('components.light_ray.no_data_found') }}</p>
                  </div>
                </div>
                <footer class="h-16 p-1 w-full flex justify-between absolute bottom-0 z-50 navheader">
                  <div class="flex w-full h-16 justify-end">
                    <button
                      class="bg-transparent text-red-800 font-semibold w-36 border border-red-800 text-white font-bold py-3 px-5 m-2 rounded-md"
                      @click="
                        $emit('resetFilter', 'locationTerm', 'locationsTerm')
                        ">
                        {{ $t('actions.reset') }}
                    </button>
                    <button class="bg-red-800 hover:bg-red-400 text-white w-40 font-bold py-3 px-5 m-2 rounded-md"
                      @click="saveClose('location')">
                      {{ $t('actions.save') }}
                    </button>
                  </div>
                </footer>
              </div>
            </Popup>
          </div>
        </template>
      </AccordionCard>
      <!-- Confidence -->
      <AccordionCard :isFilter="true">
        <template v-slot:title>
          <span class="f-title flex items-center filter-color">
            <ConfidenceScoreSVG class="mx-2" />
            {{ $t('components.light_ray.confidence_n', { value: countCheckedConfidence }) }}
          </span>
          <div class="ml-4">
            <input type="checkbox" id="checkboxInput" @change="
              $emit('handleAllCheckboxChange', $event, 'confidenceScore')
              " :checked="isAllConfidanceScoreChecked" />
            <label class="mx-1">{{ $t('components.light_ray.select_all') }}</label>
          </div>
        </template>
        <template v-slot:content>
          <div class="p-2">
            <div class="form-control">
              <Input class="w-70" icon="search" v-model="filterData.confidenceScoreTerm" @input="
                $emit('handleSearchboxChange', $event, 'confidenceScoreTerm')
                " :placeholder="$t('components.light_ray.search_confidence_score')" />
            </div>
            <div class="grid grid-cols-2 gap-4 py-2 pl-2">
              <div c v-for="cdScore in getConfidenceScore" :key="cdScore.key">
                <input type="checkbox" v-model="cdScore.checked" class="" @change="
                  $emit(
                    'handleCheckboxChange',
                    $event,
                    cdScore.key,
                    'confidenceScore'
                  )
                  " :id="cdScore.key" />
                <label class="filter-item-label mx-2">{{ cdScore.key }} ({{ cdScore.count }})</label>
              </div>
            </div>
          </div>
        </template>
      </AccordionCard>
      <AccordionCard :isFilter="true">
        <template v-slot:title>
          <span class="f-title flex items-center filter-color">
            <MatchIdentifiersSVG class="mx-2" />{{ $t('components.light_ray.matching_identifers') }}
          </span>
          <span @click="$emit('resetMatchingIdentifiers')" class="button_red_outline">{{ $t('actions.reset') }}</span>
        </template>
        <template v-slot:content>
          <div class="p-2">
            <div class="cst-input-grid">
              <div class="form-control mr-1">
                <CustomInput v-model.number="getMatchIdentifiers[0]" inputType="number" class="w-70" prefixLabel="MIN"
                  @change="setMin" :placeholder="$t('components.light_ray.match_identifiers')" />
              </div>
              <div class="form-control ml-1">
                <CustomInput v-model.number="getMatchIdentifiers[1]" inputType="number" class="w-70" prefixLabel="MAX"
                  @change="setMax" :placeholder="$t('components.light_ray.match_identifiers')" />
              </div>
            </div>
            <div class="my-10 mx-2">
              <!-- <Slider :min="0" :max="100" :value="getMatchIdentifiers" @setRange="setRange" /> -->
              <!-- <DoubleSlider :min="0" :max="100" :value="getMatchIdentifiers" /> -->
              <vue-slider v-model="filterData.matchIdentifiers" :tooltip="'always'" :enable-cross="false" :min="0"
                :max="100" range />
            </div>
            <div></div>
          </div>
        </template>
      </AccordionCard>
      <AccordionCard :isFilter="true">
        <template v-slot:title>
          <span class="f-title flex items-center filter-color">
            <InternetSVG class="mx-2" />
            {{ $t('components.light_ray.query_n', { value: checkedcountquery }) }}
          </span>
          <div class="ml-4">
            <input type="checkbox" id="checkboxInput" @change="$emit('handleAllCheckboxChange', $event, 'iQueries')"
              :checked="isAllQueriesChecked" />
            <label class="mx-1">{{ $t('components.light_ray.select_all') }}</label>
          </div>
        </template>
        <template v-slot:content>
          <div class="p-2">
            <div class="form-control">
              <Input class="w-70" icon="search" v-model="filterData.internetQueriesTerm" @input="
                $emit('handleSearchboxChange', $event, 'internetQueriesTerm')
                " :placeholder="$t('components.light_ray.search_internet_queries')" />
            </div>
            <div class="grid grid-cols-2 gap-4 py-2 pl-2">
              <div c v-for="internetQuery in getInternetQueries" :key="internetQuery.search_query">
                <input @change="
                  $emit(
                    'handleCheckboxChange',
                    $event,
                    internetQuery.maskedQuery,
                    'iQueries'
                  )
                  " type="checkbox" :checked="internetQuery.checked" v-model="internetQuery.checked" class="" />
                <label class="filter-item-label mx-2">{{ internetQuery.maskedQuery }} ({{
                  internetQuery.count
                }})</label>
              </div>
            </div>
            <!-- v-if="seeMoreIquiries" -->
            <span  @click="$emit('handleSeeMore', true, 'iQueries')"
              class="f-title cursor-pointer pl-2">{{ $t('components.light_ray.load_more') }}</span>
            <!-- <span v-if="filterData.seeMoreQueries" @click="$emit('handleSeeMore', false, 'seeMoreQueries')"
                            class="f-title cursor-pointer pl-2">Show Less</span> -->
          </div>
        </template>
      </AccordionCard>
      <AccordionCard :isFilter="true" class="pb-5">
        <template v-slot:title>
          <span class="f-title flex items-center filter-color">
            <TopicClassificationSVG class="mx-2" />{{ $t('components.light_ray.topic_classification') }}
          </span>
          <div class="ml-4">
            <input type="checkbox" id="checkboxInput" @change="
              $emit('handleAllCheckboxChange', $event, 'topic_classification')
              " :checked="isAllTCChecked" />
            <label class="mx-1">{{ $t('components.light_ray.select_all') }}</label>
          </div>
        </template>
        <template v-slot:content>
          <div class="p-2">
            <div class="form-control">
              <Input class="w-70" icon="search" v-model="filterData.topicClassifiactionTerm" @input="
                $emit(
                  'handleSearchboxChange',
                  $event,
                  'topicClassifiactionTerm'
                )
                " :placeholder="$t('components.light_ray.search_topic_classification')" />
            </div>
            <div class="grid grid-cols-1 gap-4 py-2 pl-2">
              <TreeNode :treeData="getTopicClassification" />
            </div>
          </div>
        </template>
      </AccordionCard>
    </div>
  </div>
</template>


<script>
import AccordionCard from "../../common/accordion";
import PeopleSVG from "../../assets/svg/people.svg";
import Input from "../../../input";
import CustomInput from "../../components/CustomInput/CustomInput.vue";

import CompanySVG from "../../assets/svg/company.svg";
import InternetSVG from "../../assets/svg/internet.svg";
import LocationSVG from "../../assets/svg/location.svg";
import MatchIdentifiersSVG from "../../assets/svg/match-identifier.svg";
import RiskSVG from "../../assets/svg/risk.svg";
// import StatusSVG from "../../assets/svg/status.svg";
import TopicClassificationSVG from "../../assets/svg/tpoic-classification.svg";
import ConfidenceScoreSVG from "../../assets/svg/confidence-score.svg";
// import Slider from '../RangeSlider/index.vue'
import TreeNode from "../TreeNode/index.vue";
import Popup from "./popup.vue";
import Cross from "../../assets/svg/cross.svg";
import Loader from "@/components/loader";
import "@vueform/slider/themes/default.css";
// import DoubleSlider from './DoubleSlider'
import "vue-slider-component/theme/default.css";
import VueSlider from "vue-slider-component";
export default {
  components: {
    // DoubleSlider,
    VueSlider,
    AccordionCard,
    CompanySVG,
    InternetSVG,
    LocationSVG,
    MatchIdentifiersSVG,
    RiskSVG,
    // StatusSVG,
    TopicClassificationSVG,
    ConfidenceScoreSVG,
    PeopleSVG,
    Input,
    CustomInput,
    // Slider,
    TreeNode,
    Popup,
    Cross,
    Loader,
  },
  data() {
    return {
      dataModel: [
        {
          id: "numberOrString",
          label: this.$t('components.light_ray.root_node'),
          children: [
            { id: 1, label: this.$t('components.light_ray.child_node')},
            { id: "node2", label: this.$t('components.light_ray.second_child')},
          ],
        },
      ],
      google: {},
      map: {},
      showMenu: [
        {
          id: 1,
          isOpen: false,
          type: "person",
        },
        {
          id: 2,
          isOpen: false,
          type: "company",
        },
        {
          id: 3,
          isOpen: false,
          type: "risk",
        },
        {
          id: 4,
          isOpen: false,
          type: "location",
        },
      ],
      alphabet: "ABCDEFGHIJKLMNOPQRSTUVWXYZ#".split(""),
      // loadFilter: []
    };
  },
  props: {
    filterData: {
      type: Object,
    },
    handleSearchboxChange: Function,
    handleCheckboxChange: Function,
    handleSeeMore: Function,
    loading: Boolean,
  },
  async mounted() {
    // this.google = window.google;
    // let map;
    // const position = { lat: 51.509865, lng: -0.118092 };
    // // const position = { lat: this.filterData.locations[0]?.geocode?.lat, lng: this.filterData.locations[0]?.geocode?.lon };
    // const { Map } = await this.google.maps.importLibrary("maps");
    // map = new Map(document.getElementById("map"), {
    //     zoom: 4,
    //     center: position,
    //     mapId: "DEMO_MAP_ID",
    // });
    // this.map = map
    // this.filterData.locations.map(item => {
    //     new AdvancedMarkerElement({
    //         map: map,
    //         position: { lat: item.geocode?.lat, lng: item.geocode?.lon },
    //         title: item.key,
    //     });
    // })
  },
  computed: {
    dividedData() {
      const data = this.filterData.persons;
      const numberOfRows = 6;
      const divided = [];
      for (let i = 0; i < numberOfRows; i++) {
        const row = [];
        for (let j = i; j < data.length; j += numberOfRows) {
          row.push(data[j]);
        }
        divided.push(row);
      }
      return divided;
    },
    dividedOrgData() {
      const data = this.filterData.organisations;
      const numberOfRows = 6;
      const divided = [];
      for (let i = 0; i < numberOfRows; i++) {
        const row = [];
        for (let j = i; j < data.length; j += numberOfRows) {
          row.push(data[j]);
        }
        divided.push(row);
      }
      return divided;
    },
    dividedRiskData() {
      // const rows = 6
      // const itemsPerRow = Math.ceil(this.filterData.risks.length / rows);
      // const divided = [];

      // for (let i = 0; i < rows; i++) {
      //     divided.push(this.filterData.risks.slice(i * itemsPerRow, (i+1) * itemsPerRow));
      // }

      // return divided;
      const data = this.filterData.risks;
      const numberOfRows = 6;
      const divided = [];
      for (let i = 0; i < numberOfRows; i++) {
        const row = [];
        for (let j = i; j < data.length; j += numberOfRows) {
          row.push(data[j]);
        }
        divided.push(row);
      }
      return divided;
    },
    dividedLocData() {
      const data = this.filterData.locations;
      const numberOfRows = 6;
      const divided = [];
      for (let i = 0; i < numberOfRows; i++) {
        const row = [];
        for (let j = i; j < data.length; j += numberOfRows) {
          row.push(data[j]);
        }
        divided.push(row);
      }
      return divided;
    },

    loadMoreData() {
      return this.filterData;
    },
    seeMorePeople() {
      return (
        this.filterData.personsTotalPages > this.filterData.personsCurrentPage
      );
    },
    seeMoreOrganisations() {
      return (
        this.filterData.organisationsTotalPages >
        this.filterData.organisationsCurrentPage
      );
    },
    seeMoreRisks() {
      return (
        this.filterData.riskssTotalPages >= this.filterData.riskssCurrentPage ||
        this.filterData.risks.length > 6
      );
    },
    seeMoreLocations() {
      return (
        this.filterData.locationsTotalPages >
        this.filterData.locationsCurrentPage
      );
    },
    seeMoreIquiries() {
      return (
        this.filterData.iquiriesTotalPages > this.filterData.iquiriesCurrentPage
      );
    },
    isAllPeopleChecked() {
      const checked = this.filterData.persons.filter((item) => item.checked);
      const count = this.filterData.persons.length;
      return checked.length === count && count !== 0;
    },
    peopleCheckedCount() {
      return this.filterData.persons.filter((item) => item.checked).length || 0;
    },

    getPeople() {
      // const results = this.filterData.names.filter(item => this.caseInsensitiveSearch(item.key, this.filterData.peopleTerm))
      // const max = results.length > 5 ? 5 : results.length
      return this.filterData.persons.slice(0, 6);
    },
    isAllCompanyChecked() {
      const checked = this.filterData.organisations.filter(
        (item) => item.checked
      );
      const count = this.filterData.organisations.length;
      return checked.length === count && count !== 0;
    },
    companyCheckedCount() {
      return this.filterData.organisations.filter((item) => item.checked)
        .length;
    },
    getCompanies() {
      const results = this.filterData.organisations.filter((item) =>
        this.caseInsensitiveSearch(item.key, this.filterData.companyTerm)
      );
      // const max = results.length > 5 ? 5 : results.length
      return results.slice(0, 6);
    },
    isAllStatusChecked() {
      const checked = this.filterData.status.filter((item) => item.checked);
      const count = this.filterData.status.length;
      return checked.length === count && count !== 0;
    },
    statusCheckedCount() {
      return this.filterData.status.filter((item) => item.checked).length || 0;
    },
    getStatus() {
      const results = this.filterData.status.filter((item) =>
        this.caseInsensitiveSearch(item.key, this.filterData.statusTerm)
      );
      return results;
    },
    getLocation() {
      // const results = this.filterData.locations.filter(item => this.caseInsensitiveSearch(item.key, this.filterData.locationTerm))
      // const max = results.length > 5 ? 5 : results.length
      // return this.filterData.seeMoreLocation ? results : results.slice(0, max)
      return this.filterData.locations.slice(0, 6);
    },
    isAllLocationChecked() {
      const checked = this.filterData.locations.filter((item) => item.checked);
      const count = this.filterData.locations.length;
      return checked.length === count && count !== 0;
    },
    locationCheckedCount() {
      return this.filterData.locations.filter((item) => item.checked).length;
    },
    isAllRisksChecked() {
      const checked = this.filterData.risks.filter((item) => item.checked);
      const count = this.filterData.risks.length;
      return checked.length === count && count !== 0;
    },
    riskCheckedCount() {
      return this.filterData.risks.filter((item) => item.checked).length;
    },
    getRisks() {
      // const results = this.filterData.risks.filter(item => this.caseInsensitiveSearch(item.key, this.filterData.riskTerm))
      // const max = results.length > 5 ? 5 : results.length
      // return this.filterData.seeMoreRisks ? results : results.slice(0, max)
      return this.filterData.risks.slice(0, 6);
    },
    isAllTCChecked() {
      const checkedList = this.isAllchecked(
        this.filterData.topic_classification
      );
      return checkedList.length && checkedList.every((i) => i);
    },
    getTopicClassification() {
      this.filterData.topic_classification.filter((item) =>
        this.caseInsensitiveSearch(
          item.key,
          this.filterData.topicClassifiactionTerm
        )
      );
      let searchResults = this.filterItemsRecursively(
        this.filterData.topic_classification,
        this.filterData.topicClassifiactionTerm
      );
      return this.filterData.topicClassifiactionTerm
        ? searchResults
        : this.filterData.topic_classification;
    },
    isAllConfidanceScoreChecked() {
      const checked = this.filterData.confidenceScore.filter(
        (item) => item.checked
      );
      const count = this.filterData.confidenceScore.length;
      return checked.length === count && count !== 0;
    },
    countCheckedConfidence() {
      return (
        this.filterData.confidenceScore.filter((item) => item.checked).length ||
        0
      );
    },
    getConfidenceScore() {
      const results = this.filterData.confidenceScore.filter((item) =>
        this.caseInsensitiveSearch(
          item.key,
          this.filterData.confidenceScoreTerm
        )
      );
      return results;
    },
    isAllQueriesChecked() {
      const checked = this.filterData.iQueries.filter((item) => item.checked);
      const count = this.filterData.iQueries.length;
      return checked.length === count && count !== 0;
    },
    checkedcountquery() {
      return (
        this.filterData.iQueries.filter((item) => item.checked).length || 0
      );
    },
    getInternetQueries() {
      const results = this.filterData.iQueries.filter((item) =>
        this.caseInsensitiveSearch(
          item.search_query,
          this.filterData.internetQueriesTerm
        )
      );
      return results;
      // const max = results.length > 5 ? 5 : results.length
      // return this.filterData.seeMoreQueries ? results : results.slice(0, max)
      // return this.filterData.iQueries
    },
    getMatchIdentifiers() {
      return this.filterData.matchIdentifiers;
    },
  },
  methods: {
    compareChecked(a, b) {
      if (a.checked && !b.checked) return -1;
      if (!a.checked && b.checked) return 1;
      return 0;
    },
    isAllchecked(data) {
      let arr = [];
      for (let item of data) {
        arr.push(item.checked);
        if (item?.children?.length) {
          arr.push(...this.isAllchecked(item.children));
        }
      }
      return arr;
    },
    openMenu(type) {
      if (type == this.showMenu[0].type) {
        this.showMenu[0].isOpen = true;
      } else if (type == this.showMenu[1].type) {
        this.showMenu[1].isOpen = true;
      } else if (type == this.showMenu[2].type) {
        this.showMenu[2].isOpen = true;
      } else if (type == this.showMenu[3].type) {
        this.showMenu[3].isOpen = true;
      }
    },
    closeMenu(type) {
      // this.showMenu = false
      if (type == this.showMenu[0].type) {
        this.$emit("resetFilter", "namesTerm", "personsTerm");
        this.showMenu[0].isOpen = false;
      } else if (type == this.showMenu[1].type) {
        this.$emit("resetFilter", "organisationsTerm");
        this.showMenu[1].isOpen = false;
      } else if (type == this.showMenu[2].type) {
        this.$emit("resetFilter", "riskTerm", "riskssTerm");
        this.showMenu[2].isOpen = false;
      } else if (type == this.showMenu[3].type) {
        this.$emit("resetFilter", "locationTerm", "locationsTerm");
        this.showMenu[3].isOpen = false;
      }
    },
    saveClose(type) {
      if (type == this.showMenu[0].type) {
        this.showMenu[0].isOpen = false;
      } else if (type == this.showMenu[1].type) {
        this.showMenu[1].isOpen = false;
      } else if (type == this.showMenu[2].type) {
        this.showMenu[2].isOpen = false;
      } else if (type == this.showMenu[3].type) {
        this.showMenu[3].isOpen = false;
      }
    },
    resetItem() {
      this.filterData = {
        ...this.filterData,
        persons: this.filterData.persons,
      };
    },
    filterItemsRecursively(items, query, parent) {
      let arr = [];
      for (const item of items) {
        if (this.caseInsensitiveSearch(item.category, query)) {
          if (item.level == 1) {
            arr.push(item);
          } else {
            arr.push(parent);
          }
        } else {
          if (item?.children?.length) {
            arr.push(
              ...this.filterItemsRecursively(
                item.children,
                query,
                parent || item
              )
            );
          }
        }
      }
      return arr;
    },
    chekIfAlreadyExist(arr, item) {
      for (let node of arr) {
        if (node.category == item.category) {
          return true;
        } else {
          return false;
        }
      }
    },
    setRange(e) {
      this.$emit("setRange", e);
    },
    setMin(e) {
      const MIN = e.target.value
        ? parseInt(this.filterNonNumeric(e.target.value))
        : this.filters.matchIdentifiers.min;
      this.$emit("setMin", MIN);
    },
    setMax(e) {
      const MAX = e.target.value
        ? parseInt(this.filterNonNumeric(e.target.value))
        : this.filters.matchIdentifiers.max;
      this.$emit("setMax", MAX);
    },
    filterNonNumeric(val) {
      // Replace non-numeric characters with an empty string
      return val.replace(/[^0-9]/g, "");
    },
    caseInsensitiveSearch(targetString, searchString) {
      const regex = new RegExp(searchString, "i");
      return regex.test(targetString);
    },
    // async createMarkers() {
    //     const { AdvancedMarkerElement } = await this.google.maps.importLibrary("marker");
    //     let data = this.filterData.locations.filter(i => i.geocode?.lat && i.geocode?.lon)
    //     if (this.map && Array.isArray(data)) {
    //         data.forEach(item => {
    //             new AdvancedMarkerElement({
    //                 map: this.map,
    //                 position: { lat: item.geocode?.lat, lng: item.geocode?.lon },
    //                 title: item.key,
    //             });
    //         });
    //     }

    // },
    // sortData(){
    //     this.filterData.persons.sort(this.compareChecked)
    // }
    getTitle(item) {
      return item?.key + ' (' + item.count + ')'
    }
  },
  async created() {
    // this.createMarkers();
  },
  // watch: {
  //     'filterData.persons': {
  //         handler: 'sortData',
  //         deep: true,
  //     },
  // },
};
</script>
<style>
.w-100 {
  width: 100%;
}

.active:hover {
  background-color: #27272752;
}

.active:focus {
  background-color: #27272752;
}

.active {
  padding: 4px;
  margin: 0.425rem 0.075rem !important;
}

.f-title {
  color: var(--light-ray-main-red, #ce1b22);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.cst-input-grid {
  display: flex;
  justify-content: space-between;
}

.filters-container {
  /* max-height: 62vh; */
  /* min-height: 30vh; */
  overflow: scroll;
}

.button_red_outline {
  border-radius: 6px;
  color: #ce1b22 !important;
  background-color: white;
}

.button_red_outline:hover {
  border-color: $lightray_product_color;
  background-color: white !important;
  color: white;
}
</style>
