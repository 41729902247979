<!-- Localized -->
<template>
  <div class="scroll overflow-y-scroll " style="max-height: 80vh;" ref="news">
    <div :data-attribute="this.matching">
      <div class="flex  justify-end">
        <span id="print-button" class="print-hidden inline-flex  cursor-pointer hover:text-brand-hover"
          @click="printDocuments('printDataSection', 'adverse', 'adverse')">
          <span
            class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800 capitalize"
            v-tippy :content="$t('components.light_ray.print')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-printer-fill"
              viewBox="0 0 16 16">
              <path
                d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" />
              <path
                d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z" />
            </svg>
            <!-- <div class="mr-2">Print</div> -->
            <!-- <neo-loader class="" :loading="isPrintLoader" /> -->
          </span>
        </span>
      </div>
      <div class="text-xl font-medium  flex flex-col ">
        <div>{{ adverseCard?.doc?.name }}</div>
        <div class="badge badge-primary text-sm">{{ adverseCard?.doc?.entity_type }}</div>
        <div v-if="adverseCard.doc?.aka" class="flex items-center flex-wrap max-w-full mb-3 name headingBlock-sibling">
          <span class="text-gray-400 mt-2">{{ $t('components.light_ray.name_variations') }}</span>
          <template v-if="adverseCard?.doc?.aka?.length > 0">
            <span class="ml-2 flex max-w-full items-center mr-4 mt-2" v-for="name in aliasNames(adverseCard, this.module)"
              :key="name">
              <span class="font-medium mr-2 text-gray-500 inline-block truncate max-w-full">{{ name }}</span>
            </span>
          </template>
          <template v-else>
            <span class="text-gray-400">{{ $t('components.light_ray.') }}</span>
          </template>
        </div>
        <div class="flex flex-wrap headingBlock-sibling">
          <span v-for="(tag, index) in parseTypes(implode(adverseCard?.doc?.types))" :key="`${tag}___${index}`"
            class="px-2  py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-700  mt-1 mr-2 capitalize">
            <span>
              {{ tag }}
            </span>
          </span>
        </div>
        <div class="text-gray-500 text-sm"></div>
      </div>
      <!-- expand and collaspe -->
      <div class="print-d-block  flex  flex-col  space-y-4  overflow-y-auto  px-2  overflow-x-hidden">
        <div class="print-hidden text-blue-600  ml-auto  cursor-pointer  inline-flex">
          <div @click="handleExpandAll" v-if="checkExpand">{{ $t('components.light_ray.expand_all') }}</div>
          <div @click="handleCollapseAll" v-else>{{ $t('components.light_ray.collapse_all') }}</div>
        </div>
      </div>
      <div id="printDataSection">
        <!-- Details -->
        <template v-if="adverseCard?.doc?.fields">
          <div @click="expandedDetails.Details = !expandedDetails.Details"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">{{ $t('components.light_ray.details') }}</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{ 'rotate-180': expandedDetails.Details }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.Details">
            <div class="flex space-x-2" v-for="field in getMasterFields(adverseCard.doc.fields)" :key="field.value">
              <template v-if="field.name == 'Countries'">
                <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">{{ field.name }}</div>
                <div class="w-2/3 lg:h-3/4 pl-3 pr-3 py-2">
                  <div class="flex justify-between items-center">
                    <span class="space-y-2">
                      <template>{{ field.value }}</template>
                    </span>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">{{ $t('components.light_ray.details') }}</div>
          </div>
        </template>
        <!-- Associates -->
        <template v-if="adverseCard?.doc.associates">
          <div @click="expandedDetails.Associates = !expandedDetails.Associates"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">{{ $t('components.light_ray.associates') }}</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{ 'rotate-180': expandedDetails.Associates }" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.Associates">
            <div class="gap-y-1 gap-x-2 mt-2" v-if="adverseCard.doc.associates">
              <div v-for="associate in adverseCard.doc.associates" class="break-words capitalize"
                :key="associate.association">
                <div class="flex">
                  <div class="inline-block flex-wrap mb-1">
                    <span class="font-medium break-words">
                      {{ associate.name }}
                    </span>
                    <span>
                      ({{ associate.association }})
                    </span>
                  </div>
                  <neo-analyse class="ml-2" v-if="associate.name" source="name" :value="associate.name"></neo-analyse>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">{{ $t('components.light_ray.associates') }}</div>
          </div>
        </template>
        <!-- Sanction-->
        <template v-if="adverseCard?.doc?.source_notes.length > 0">
          <div @click="expandedDetails.Sanction = !expandedDetails.Sanction"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">{{ $t('components.light_ray.sanction') }}</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{ 'rotate-180': expandedDetails.Sanction }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.Sanction">
            <div class="grid grid-cols-1 gap-y-2 gap-x-2" v-if="checkSanction(adverseCard.doc.source_notes)">
              <template v-for="(i, j) in adverseCard.doc.source_notes">
                <article v-if="i.aml_types && i.aml_types.includes('sanction')" :key="j">
                  <template>
                    <div class="border border-solid border-gray-300 rounded-md p-2">
                      <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium">
                        {{ i.name }}
                      </a>
                      <div class="mt-1 text-sm">
                        {{ $t('components.light_ray.listed') }}
                        <span class="font-medium">
                          {{ parseDate(i.listing_started_utc) }} - {{ i.listing_ended_utc ? parseDate(i.listing_ended_utc)
                            :
                            "Present" }}
                        </span>
                        <span class="ml-8" v-if="i.listing_ended_utc">
                          {{ $t('components.light_ray.removed') }}
                          <span class="font-medium">
                            {{ i.listing_ended_utc ? parseDate(i.listing_ended_utc) : "Present" }}
                          </span>
                        </span>
                      </div>
                      <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                        <div v-for="(field, index) in getFilteredFields(adverseCard.doc.fields, j)" class="break-words"
                          :key="field.value + '0' + index">
                          <span class="font-medium">{{ field.name }}: </span>
                          <template v-if="urlFields.includes(field.name.toLowerCase())">
                            <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{
                              field.value }}</a>
                          </template>
                          <template v-else>
                            <span class="">
                              {{ field.value }}
                            </span>
                          </template>
                        </div>
                      </div>
                    </div>
                  </template>
                </article>
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">{{ $t('components.light_ray.sanction') }}</div>
          </div>
        </template>
        <!-- PEP / Warnings Details -->
        <template v-if="adverseCard?.doc?.source_notes">
          <div @click="expandedDetails.pep = !expandedDetails.pep"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">{{ $t('components.light_ray.pep__warnings_details') }}</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{ 'rotate-180': expandedDetails.pep }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.pep">
            <template v-for="(i, j) in adverseCard.doc.source_notes">
              <article :key="j" v-if="i.aml_types && i.aml_types.includes('warning')">
                <template>
                  <div class="border border-solid border-gray-300 rounded-md p-2 mb-3">
                    <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium">
                      {{ i.name }}
                    </a>
                    <div class="mt-1 text-sm">
                      {{ $t('components.light_ray.listed') }}
                      <span class="font-medium">
                        {{ parseDate(i.listing_started_utc) }} - {{ i.listing_ended_utc ? parseDate(i.listing_ended_utc) :
                          "Present" }}
                      </span>
                      <span class="ml-8" v-if="i.listing_ended_utc">
                        {{ $t('components.light_ray.removed') }}<span class="font-medium">{{ i.listing_ended_utc ?
                          parseDate(i.listing_ended_utc) : "Present" }}</span>
                      </span>
                    </div>
                    <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                      <div v-for="(field, index) in getFilteredFields(adverseCard.doc.fields, j)" class="break-words"
                        :key="field.value + index">
                        <span class="font-medium">
                          {{ field.name }}:
                        </span>
                        <template v-if="urlFields.includes(field.name.toLowerCase())">
                          <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">
                            {{ field.value }}
                          </a>
                        </template>
                        <template v-else>
                          <span class="">
                            {{ field.value }}
                          </span>
                        </template>
                      </div>
                    </div>
                  </div>
                </template>
              </article>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">{{ $t('components.light_ray.pep__warnings_details') }}</div>
          </div>
        </template>
        <!-- PEP / pepDetailsExpanded -->
        <template
          v-if="adverseCard.doc && (checkPep4(adverseCard.doc.source_notes, 'pep-class-1') || checkPep4(adverseCard.doc.source_notes, 'pep-class-2') || checkPep4(adverseCard.doc.source_notes, 'pep-class-3') || checkPep4(adverseCard.doc.source_notes, 'pep-class-4') || checkPep4(adverseCard.doc.source_notes))">
          <div @click="expandedDetails.pepDetailsExpanded = !expandedDetails.pepDetailsExpanded"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">{{ $t('components.light_ray.pep_listing') }}</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{ 'rotate-180': expandedDetails.pepDetailsExpanded }" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.pepDetailsExpanded">
            <div class="p-2 ">
              <div class="text-base mb-3 mt-4 font-bold">{{ $t('components.light_ray.pep') }}</div>
              <template v-if="adverseCard.doc">
                <div class="grid-cols-1 mb-4 gap-y-2 gap-x-2">
                  <section v-for="(i, j, index) in adverseCard.doc.source_notes" :key="j">
                    <template v-if="i.name == 'ComplyAdvantage PEP Data'">
                      <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                        <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium">
                          {{ i.name }}
                        </a>
                        <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                          <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)"
                            :key="field.name + '___' + idx" class="break-words">
                            <span class="font-medium">{{ field.name }}: </span>
                            <template v-if="urlFields.includes(field.name.toLowerCase())">
                              <a class="no-underline text-blue-600" target="_blank" rel="noreferrer"
                                :href="field.value">{{
                                  field.value }}</a>
                            </template>
                            <template v-else>{{ field.value }}</template>
                          </div>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <template
                        v-if="Object.keys(adverseCard.doc.source_notes).length - 1 == index && checkComplyAdvantage(adverseCard.doc.source_notes) == 0">
                        {{ $t('components.light_ray.no_data_found') }}</template>
                    </template>
                  </section>
                </div>
              </template>
              <template v-else>{{ $t('components.light_ray.no_data_found') }}</template>
              <div class="text-base my-3 font-bold">{{ $t('components.light_ray.pep_class_n', { value: '1' }) }}</div>
              <!-- <div class="mb-2 flex space-x-2" v-for="field in getMasterFields(adverseCard.doc.fields)" :key="field.value">
                        <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400" v-if="field.name!='Countries'">{{ field.name }}</div>
                        <div class="w-2/3 lg:h-3/4 pl-3 pr-3 py-2" v-if="field.name!='Countries'">
                            <div class="flex justify-between items-center">
                                <span class="space-y-2">
                                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                                        <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{ field.value }}</a>
                                    </template>
                                    <template v-else>{{ field.value }}</template>
                                </span>
                            </div>
                        </div>
                    </div> -->
              <template v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-1') && adverseCard.doc">
                <div class="grid grid-cols-1 mb-2 gap-y-2 gap-x-2"
                  v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-1')">
                  <template v-for="(i, j) in adverseCard.doc.source_notes">
                    <section
                      v-if="i.aml_types && i.aml_types.includes('pep-class-1') && i.name != 'ComplyAdvantage PEP Data'"
                      :key="j">
                      <template>
                        <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                          <a target="_blank" rel="noreferrer" :href="i.url"
                            class="text-blue-600 no-underline font-medium">{{
                              i.name }}</a>
                          <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                            <div v-for="field, j in getFilteredFields(adverseCard.doc.fields, j)" :key="field.name + j"
                              class="break-words">
                              <span class="font-medium">{{
                                field.name }} {{ (adverseCard.doc.source_notes).length }}: </span>
                              <template v-if="urlFields.includes(field.name.toLowerCase())">
                                <a class="no-underline text-blue-600" target="_blank" rel="noreferrer"
                                  :href="field.value">
                                  {{ field.value }}
                                </a>
                              </template>
                              <template v-else>{{ field.value }}</template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </section>
                    <template v-else>
                      <template
                        v-if="checkPepLength(adverseCard.doc.source_notes, 'pep-class-1') == 1 && i.name == 'ComplyAdvantage PEP Data' && i && i.aml_types && i.aml_types.includes('pep-class-1')">
                        {{ $t('components.light_ray.no_data_found') }}</template>
                    </template>
                  </template>
                </div>
              </template>
              <template v-else>{{ $t('components.light_ray.no_data_found') }}</template>

              <div class="text-base mb-3 mt-4 font-bold">{{ $t('components.light_ray.pep_class_n', { value: '2' }) }}</div>
              <template v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-2') && adverseCard.doc">
                <div class="grid-cols-1 mb-4 gap-y-2 gap-x-2"
                  v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-2')">
                  <template v-for="(i, j) in adverseCard.doc.source_notes">
                    <section
                      v-if="i.aml_types && i.aml_types.includes('pep-class-2') && i.name != 'ComplyAdvantage PEP Data'"
                      :key="j">
                      <template>
                        <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                          <a target="_blank" rel="noreferrer" :href="i.url"
                            class="text-blue-600 no-underline font-medium">
                            {{ i.name }}
                          </a>
                          <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                            <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)"
                              :key="field.name + '___' + idx" class="break-words">
                              <span class="font-medium">{{ field.name }}: </span>
                              <template v-if="urlFields.includes(field.name.toLowerCase())">
                                <a class="no-underline text-blue-600" target="_blank" rel="noreferrer"
                                  :href="field.value">{{
                                    field.value }}</a>
                              </template>
                              <template v-else>{{ field.value }}</template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </section>
                    <template v-else>
                      <template
                        v-if="checkPepLength(adverseCard.doc.source_notes, 'pep-class-2') == 1 && i.name == 'ComplyAdvantage PEP Data' && i && i.aml_types && i.aml_types.includes('pep-class-2')">
                        {{ $t('components.light_ray.no_data_found') }}</template>
                    </template>
                  </template>
                </div>
              </template>
              <template v-else>{{ $t('components.light_ray.no_data_found') }}</template>

              <div class="text-base mb-3 mt-4 font-bold">{{ $t('components.light_ray.pep_class_n', { value: '3' }) }}</div>
              <template v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-3') && adverseCard.doc">
                <div class="grid-cols-1 mb-4 gap-y-2 gap-x-2"
                  v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-3')">
                  <template v-for="(i, j) in adverseCard.doc.source_notes">
                    <section
                      v-if="i.aml_types && i.aml_types.includes('pep-class-3') && i.name != 'ComplyAdvantage PEP Data'"
                      :key="j">
                      <template>
                        <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                          <a target="_blank" rel="noreferrer" :href="i.url"
                            class="text-blue-600 no-underline font-medium">
                            {{ i.name }}
                          </a>
                          <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                            <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)"
                              :key="field.name + idx" class="break-words">
                              <span class="font-medium">{{ field.name }}: </span>
                              <template v-if="urlFields.includes(field.name.toLowerCase())">
                                <a class="no-underline text-blue-600" target="_blank" rel="noreferrer"
                                  :href="field.value">
                                  {{ field.value }}
                                </a>
                              </template>
                              <template v-else>{{ field.value }}</template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </section>
                    <template v-else>
                      <template
                        v-if="checkPepLength(adverseCard.doc.source_notes, 'pep-class-3') == 1 && i.name == 'ComplyAdvantage PEP Data' && i && i.aml_types && i.aml_types.includes('pep-class-3')">
                      {{ $t('components.light_ray.no_data_found') }}</template>
                    </template>
                  </template>
                </div>
              </template>
              <template v-else>{{ $t('components.light_ray.no_data_found') }}</template>

              <div class="text-base mb-3 mt-4 font-bold">{{ $t('components.light_ray.pep_class_n', { value: '4' }) }}</div>
              <template v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-4') && adverseCard.doc">
                <div class="grid-cols-1 mb-4 gap-y-2 gap-x-2"
                  v-if="checkPep4(adverseCard.doc.source_notes, 'pep-class-4')">
                  <template v-for="(i, j) in adverseCard.doc.source_notes">
                    <section
                      v-if="i.aml_types && i.aml_types.includes('pep-class-4') && i.name != 'ComplyAdvantage PEP Data'"
                      :key="j">
                      <template>
                        <div class="border border-solid border-gray-300 rounded-md p-2" v-if="i">
                          <a target="_blank" rel="noreferrer" :href="i.url"
                            class="text-blue-600 no-underline font-medium">
                            {{ i.name }}
                          </a>
                          <div class="grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                            <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)"
                              :key="field.name + idx" class="break-words">
                              <span class="font-medium">{{ field.name }}:
                              </span>
                              <template v-if="urlFields.includes(field.name.toLowerCase())">
                                <a class="no-underline text-blue-600" target="_blank" rel="noreferrer"
                                  :href="field.value">{{
                                    field.value }}</a>
                              </template>
                              <template v-else>{{ field.value }}</template>
                            </div>
                          </div>
                        </div>
                      </template>
                    </section>
                    <template v-else>
                      <template
                        v-if="checkPepLength(adverseCard.doc.source_notes, 'pep-class-4') == 1 && i.name == 'ComplyAdvantage PEP Data' && i && i.aml_types && i.aml_types.includes('pep-class-4')">
                        {{ $t('components.light_ray.no_data_found') }}</template>
                    </template>
                  </template>
                </div>
              </template>
              <template v-else>{{ $t('components.light_ray.no_data_found') }}</template>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">{{ $t('components.light_ray.pep') }}</div>
          </div>
        </template>
        <!-- PEP / Fitness Probity -->
        <template v-if="checkFitnessProbityList(adverseCard.doc.source_notes)">
          <div @click="expandedDetails.Fitness = !expandedDetails.Fitness"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">{{ $t('components.light_ray.fitness_probity') }}</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{ 'rotate-180': expandedDetails.Fitness }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.Fitness">
            <article v-for="(i, j) in adverseCard.doc.source_notes" :key="j" class="mb-2">
              <div class="border border-solid border-gray-300 rounded-md p-2"
                v-if="i.aml_types && i.aml_types.includes('fitness-probity')">
                <a target="_blank" rel="noreferrer" :href="i.url" class="text-blue-600 no-underline font-medium">{{ i.name
                }}</a>
                <div class="print-d-block grid grid-cols-4 gap-y-1 gap-x-2 mt-2">
                  <div v-for="(field, idx) in getFilteredFields(adverseCard.doc.fields, j)" class="break-words"
                    :key="field.value + idx">
                    <span class="font-medium">{{ field.name }}: </span>
                    <template v-if="urlFields.includes(field.name.toLowerCase())">
                      <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">
                        {{ field.value }}
                      </a>
                    </template>
                    <template v-else>{{ field.value }}</template>
                  </div>
                </div>''
              </div>
            </article>
          </div>
        </template>
        <template v-else>
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">{{ $t('components.light_ray.fitness_probity') }}</div>
          </div>
        </template>
        <!--Asset  -->
        <template v-if="getImages(adverseCard.doc.assets).images.length">
          <div @click="expandedDetails.Assets = !expandedDetails.Assets"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">{{ $t('components.light_ray.assets') }}</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{ 'rotate-180': expandedDetails.Assets }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.Assets">
            <div class="py-4" v-if="getImages(adverseCard.doc.assets).images.length">
              <h5 class="text-sm text-gray-600">{{ $t('components.light_ray.images') }}</h5>
              <img referrerpolicy="no-referrer" class="h-60"
                v-for="(image, idx) in getImages(adverseCard.doc.assets).images" :src="image.url"
                :key="image.url + idx" />
            </div>
            <div class="py-4" v-if="getImages(adverseCard.doc.assets).pdf.length">
              <h5 class="text-sm text-gray-600">{{ $t('components.light_ray.documents') }}</h5>
              <a :href="assets.public_url" referrerpolicy="no-referrer" target="_blank"
                class="text-brand hover:text-purple-700 block mb-2" :key="assets.public_url + idx"
                v-for="(assets, idx) in getImages(adverseCard.doc.assets).pdf">
                {{ assets.public_url }}
              </a>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">{{ $t('components.light_ray.assets') }}</div>
          </div>
        </template>
        <!--media  -->
        <template v-if="isMedia && getFilteredFields(adverseCard.doc.fields, 'complyadvantage-adverse-media').length > 0">
          <div @click="expandedDetails.adversemedialist = !expandedDetails.adversemedialist"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">{{ $t('components.light_ray.adverse_media_list') }}</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{ 'rotate-180': expandedDetails.adversemedialist }" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.adversemedialist">
            <template
              v-if="isMedia && getFilteredFields(adverseCard.doc.fields, 'complyadvantage-adverse-media').length > 0">
              <div class="p-2 headingBlock-sibling" v-if="expandedDetails.adversemedialist">
                <!-- <div class="border border-solid border-gray-300 rounded-md p-2">
                        <a target="_blank" rel="noreferrer" class="text-blue-600 no-underline font-medium"> ComplyAdvantage Adverse-Media</a> -->
                <div class="mb-2 flex space-x-2"
                  v-for="field in getFilteredFields(adverseCard.doc.fields, 'complyadvantage-adverse-media')"
                  :key="field.name">
                  <div class="w-1/3 lg:w-1/4 font-semibold text-left py-2 pr-3 text-gray-400">{{ field.name }}</div>
                  <div class="w-2/3 lg:h-3/4 pl-3 pr-3 py-2">
                    <div class="flex justify-between items-center">
                      <span class="space-y-2">
                        <template v-if="urlFields.includes(field.name.toLowerCase())">
                          <a class="no-underline text-blue-600" target="_blank" rel="noreferrer" :href="field.value">{{
                            field.value }}</a>
                        </template>
                        <template v-else>{{ field.value }}</template>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- </div> -->
            </template>
          </div>
        </template>
        <template v-else>
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">{{ $t('components.light_ray.adverse_media_list') }}</div>
          </div>
        </template>
        <!--Advesre media  -->
        <template v-if="this.sorted.length > 0">
          <div @click="expandedDetails.media = !expandedDetails.media"
            class=" flex items-center py-1 px-2  mt-4  justify-between border border-solid accordian_color rounded cursor-pointer hover:shadow-md">
            <div class="text-lg font-medium">{{ $t('components.light_ray.adverse_media') }}</div>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 cursor-pointer transition-all transform duration-300 hover:text-brand"
                :class="{ 'rotate-180': expandedDetails.media }" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
            </div>
          </div>
          <div class="w-full max-full pl-1 pr-1 py-2" v-if="expandedDetails.media">
            <div v-for="(media, idx) in sorted" :key="media.url + idx"
              class="border rounded border-solid border-gray-300 p-2 mb-2">
              <div>
                <!-- doc.media -->
                <a target="_blank" rel="noreferrer" :href="media.url"
                  class="no-underline text-blue-600 text-base font-medium">
                  {{ media.title }}
                </a>
              </div>
              <div class="text-sm text-gray-500 mt-0.5">
                {{ $t('components.light_ray.published_v', {value: media.date}) }}</div>
              <div class="mt-2 text-justify">{{ media.snippet }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="py-1 px-2 bg-gray-50 mt-4 border border-solid border-gray-200 rounded ">
            <div class="text-lg font-medium">{{ $t('components.light_ray.adverse_media') }}</div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script >
import adverseMixin from '@shared/mixins/adverseMixin';
import printDataMixin from "@shared/mixins/printDataMixin";
export default {
  name:'adverse-detail',
  props: {
    adverseCard: Array,
    sourceName: String,
    individualData: Object,
    identifiers: Array,
  },
  mixins: [printDataMixin, adverseMixin,],
  data() {
    return {
      matching: Array,
      isPrintLoader: false,
      module: "complyAdvantage",
      urlFields: ["related url", "locationurl"],
      expandedDetails: {
        Associates: false,
        Details: false,
        Sanction: false,
        pep: false,
        pepDetailsExpanded: false,
        Fitness: false,
        adversemedialist: false,
        Assets: false,
        media: false,
      },
      expandAll: true,
      sorted: [],
      headers: [
        'Name',
        'Role',
        'From',
        'To',
        'Incorporation Date',
        'juridiction',
        'Status'
      ]
    };
  },
  methods: {
    highlightText(text, query) {
      const regex = new RegExp(`(${query})`, "gi");
      return text.replace(regex, (match) => {
        return `<span class='highlighted badge badge-warning'>${match}</span>`;
      });
    },
    parseTypes(data) {
      if (data && data?.trim()) return data?.split(",")?.map((e) => e.replace(/-/g, " ")?.replace(/None/g, "Others"));
      else return [];
    },
    handleExpandAll() {
      this.expandedDetails.Associates = true
      this.expandedDetails.Details = true
      this.expandedDetails.Sanction = true
      this.expandedDetails.pep = true
      this.expandedDetails.pepDetailsExpanded = true
      this.expandedDetails.Fitness = true;
      this.expandedDetails.Assets = true;
      this.expandedDetails.adversemedialist = true;
      this.expandedDetails.media = true;
      this.expandAll = false;
    },
    handleCollapseAll() {
      this.expandedDetails.Associates = false
      this.expandedDetails.Details = false
      this.expandedDetails.Sanction = false
      this.expandedDetails.pep = false
      this.expandedDetails.pepDetailsExpanded = false;
      this.expandedDetails.Fitness = false;
      this.expandedDetails.Assets = false;
      this.expandedDetails.adversemedialist = false;
      this.expandedDetails.media = false;
      this.expandAll = true;
    },
    isMedia() {
      if (this.adverseCard && this.adverseCard.doc.media) {
        let mediaDate = [];
        let noDate = [];
        (this.adverseCard.doc.media).forEach(e => {
          if (e.date) {
            mediaDate.push(e)
          }
          else {
            noDate.push(e)
          }
        })
        this.sorted = mediaDate.sort((a, b) => {
          if (a.date && b.date) {
            const date1 = (a.date).split("T");
            const date2 = (b.date).split("T")
            const aDate = new Date(date1[0]);
            const bDate = new Date(date2[0])
            return bDate.getTime() - aDate.getTime()
          }
        })
        if (noDate) {
          noDate.map(e => {
            this.sorted.push(e)
          })
        }

      }
      return this.sorted && this.sorted.length > 0;
    },
    getImages(assetsList) {
      let pdf = [];
      let images = [];
      if (assetsList && assetsList.length) {
        pdf = assetsList.filter((ass) => ass?.type === "pdf");
        images = assetsList.filter((ass) => ass?.type !== "pdf");
        // let others = assetsList
      }
      return { pdf, images };
    },
    implode(arr) {
      if (arr) {
        return arr.join(",");
      }
      return "";
    },
    async printDocuments(id, fileName, key, noPrint) {
      this.handleExpandAll();
      this.isPrintLoader = true;
      let htmlPDF = "";
      htmlPDF = await this.printPage(id, fileName, noPrint)
      this.handleCollapseAll();
      return htmlPDF;
    },
    async printPage(id, name, print, noPrint) {
      this.printDone = false;
      this.isPrintLoader = true;
      if (!print) {
        let htmlPDF = await this.printData(id, name, noPrint);
        this.$emit("updatePrintStatus", "");
        this.isPrintLoader = false;
        if (noPrint) {
          return htmlPDF;
        }
      }
    },

  },
  computed: {
    getNames() {
      return this.adverseCard.doc.aka
    },
    checkExpand(){
      if(this.expandedDetails.Associates && this.expandedDetails.Details && this.expandedDetails.Sanction && this.expandedDetails.pep && this.expandedDetails.pepDetailsExpanded && this.expandedDetails.Fitness &&this.expandedDetails.adversemedialist && this.expandedDetails.Assets && this.expandedDetails.media){
        return false;
      }
      else {
        return true;
      }
    }
  },
  mounted() {
    this.handleExpandAll();
    this.isMedia();
  },
  updated() {
    if (this.sorted.length == 0 && this.adverseCard && this.adverseCard.doc.media) {
      this.isMedia();
    }
    const news = this.$refs.news;
    if (news) {
      const elements = news.querySelectorAll("[data-attribute]");
      if(elements.length){
      elements.forEach((element) => {
        const innerHtml = element.innerHTML;
        let attributeValue = element.getAttribute("data-attribute");
        if(attributeValue.length)
        {
          attributeValue =  attributeValue.split(",");
          let highlightedHtml = innerHtml;
          attributeValue.forEach((att) => {
          highlightedHtml = this.highlightText(highlightedHtml, att);
          });
         element.innerHTML = highlightedHtml;
        }
      });
    }
    }
  },
  created() {
    this.matching = this.identifiers.map(i => i.entity);
  },
};

</script>
<style lang="scss" scoped>
@import "../../../assets/scss/color.scss";

.accordian_color {
  background-color: $lightray_accordian !important;
  border: 1px solid $lightray_accordian !important;
}
</style>