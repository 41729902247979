<!-- Localized -->
<template>
  <div>
    <ul>
      <li v-for="(item, index) in treeData" :key="index">
        <tree-item :data="item"></tree-item>
      </li>
    </ul>
  </div>
</template>
  
<script>
import TreeItem from './tree-item'; // Import the TreeItem component

export default {
  components: {
    TreeItem,
  },
  props: {
    treeData: {
      type: Array,
      required: true,
    },
  },
};
</script>
  