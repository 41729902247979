<!-- Localized -->
<template>
  <div class="pagination">
    <button @click="goToPage(1)" :disabled="currentPage === 1">&lt;&lt;</button>
    <button @click="prevPage" :disabled="currentPage === 1">&lt;</button>
    <template v-if="showStartEllipsis">
      <span>...</span>
    </template>
    <div v-for="pageNumber in displayedPages" :key="pageNumber">
      <button @click="goToPage(pageNumber)" :class="{ active: currentPage === pageNumber }">{{ pageNumber }}</button>
    </div>
    <template v-if="showEndEllipsis">
      <span>...</span>
    </template>
    <button @click="nextPage" :disabled="currentPage === totalPages">&gt;</button>
    <button @click="goToPage(totalPages)" :disabled="currentPage === totalPages">&gt;&gt;</button>
  </div>
</template>
<script>
export default {
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    maxDisplayedPages: {
      type: Number,
      default: 5, // Adjust this value based on your design
    },
  },
  computed: {
    showStartEllipsis() {
      return this.currentPage > 2;
    },
    showEndEllipsis() {
      return this.currentPage < this.totalPages - 1;
    },
    displayedPages() {
      const middle = Math.ceil(this.maxDisplayedPages / 2);
      let start = this.currentPage - middle + 1;
      let end = this.currentPage + middle - 1;

      if (start < 1) {
        start = 1;
        end = Math.min(this.totalPages, this.maxDisplayedPages);
      } else if (end > this.totalPages) {
        end = this.totalPages;
        start = Math.max(1, this.totalPages - this.maxDisplayedPages + 1);
      }



      const pages = [];
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }



      return pages;
    },
  },
  methods: {
    goToPage(pageNumber) {
      this.$emit('page-change', pageNumber);
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.goToPage(this.currentPage - 1);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.goToPage(this.currentPage + 1);
      }
    },
  },
};
</script>

 

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}



button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: none;
  background-color: #f0f0f0;
}



button:hover {
  background-color: #e0e0e0;
}



button:disabled {
  cursor: not-allowed;
  background-color: #ccc;
}



button.active {
  background-color: #007bff;
  color: white;
}
</style>