<!-- Localized -->
<template>
  <div class="flex items-center">
    <span
      :class="value ? `toggle-wrapper-on` : `toggle-wrapper`"
      role="checkbox"
      tabindex="0"
      @click="toggle"
      @keydown.space.prevent="toggle"
    >
      <span class="toggle-background" :class="backgroundStyles" />
      <span
        :class="value ? `toggle-indicator-on` : `toggle-indicator`"
        :style="indicatorStyles"
      />
    </span>
  </div>
</template>

<script>
export default {
  props: {
    itemChecked: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      value: this.itemChecked,
    };
  },
  watch: {
    itemChecked(newVal) {
      this.value = newVal;
    }
  },
  computed: {
    backgroundStyles() {
      return {
        "gold-mid": this.value,
        "gray-lighter": !this.value,
      };
    },
    indicatorStyles() {
      return { transform: this.value ? "translateX(14px)" : "translateX(0)" };
    },
  },
  methods: {
    toggle() {
        this.value = !this.value;
        this.$emit("input", this.value);
    },
  },
};
</script>

<style>
.gold-mid {
  background-color: #0d6ad5;
}

.gray-lighter {
  background-color: #efefef;
}

.toggle-wrapper {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 45px;
  height: 25px;
  border-radius: 9999px;
  border: 2px solid black;
}

.toggle-wrapper-on {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 45px;
  height: 25px;
  border-radius: 9999px;
}

.toggle-wrapper:focus {
  outline: 0;
}

.toggle-background {
  display: inline-block;
  border-radius: 9999px;
  height: 100%;
  width: 100%;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.4s ease;
}

.toggle-indicator {
  position: absolute;
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: black;
  border-radius: 9999px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease;
}
.toggle-indicator-on {
  position: absolute;
  height: 16px;
  width: 16px;
  left: 8px;
  bottom: 4px;
  background-color: white;
  border-radius: 9999px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.4s ease;
}
</style>
