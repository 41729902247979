<!-- Localized -->
<template>
  <div>
    <template v-if="entitiesListLoader">
      <div class="flex justify-center items-start">
        <Loader />
      </div>
    </template>
    <template >
      <main-entity
        :main_entity="entitiesList"
        :showMoreList="showMainEntityMoreList"
        @deleteEntity="deleteEntity"
        @toggleMoreList="toggleMoreList"
      />
    </template>
  <div
    class="h-full bg-white mt-3 border-slate-700 p-4 rounded-2xl parent overflow-y-auto scroll-bar"
    :style="`height: 82vh;`"
  >
    <section class="mt-5 flex justify-between gap-5 w-full h-full">
      <aside
        class="flex flex-col items-stretch w-2/12 max-md:w-full max-md:ml-0 h-full"
      >
        <header
          class="items-start border flex h-full max-w-full grow flex-col rounded-xl border-solid border-zinc-300 max-md:mt-5"
        >
          <div
            class="text-white text-xs font-semibold self-stretch whitespace-nowrap items-start dynamic_bg w-full pl-4 pr-5 py-2.5 rounded-t-xl border-b-zinc-300 border-b-opacity-90 border-b border-solid"
          >
          {{ $t('components.light_ray.sections') }}
          </div>
          <div
            class="items-start self-stretch flex flex-col pl-4 pr-5 py-3 border-b-zinc-300 border-b-opacity-90 border-b border-solid"
          >
            <div
              class="text-gray-700 text-xs font-semibold opacity-60 whitespace-nowrap self-start"
            >
            {{ $t('components.light_ray.entity_information') }}
            </div>
          </div>
          <div
            class="items-center bg-red-100 self-stretch flex w-full flex-col pl-4 pr-5 py-2.5 border_right"
          >
            <div
              class="flex w-[84px] max-w-full items-start gap-2.5 self-start"
            >
              <span class="mr-1"><group-icon :fill="mainColor" /></span>
              <div
                class="text-gray-700 text-xs font-medium self-stretch whitespace-nowrap"
              >
              {{ $t('components.light_ray.attributes') }}
              </div>
            </div>
          </div>
          <div
            class="items-start self-stretch flex w-full h-[658px] flex-col border-b-zinc-300 border-b-opacity-90 border-b border-solid"
          ></div>
        </header>
      </aside>
      <section
        class="flex flex-col items-stretch w-7/12 max-md:w-full max-md:ml-0"
      >
        <article
          class="overflow-y-auto scroll-bar items-start border flex flex-col w-full pl-4 pr-5 pt-5 pb-4 rounded-xl border-solid border-zinc-300 border-opacity-90 max-md:max-w-full max-md:mt-5"
        >
          <header
            class="text-zinc-800 text-sm font-semibold whitespace-nowrap self-start"
          >
          {{ $t('components.light_ray.attributes') }}
          </header>
          <hr
            class="mt-2 bg-slate-900 bg-opacity-10 w-full h-px self-start max-md:max-w-full"
          />
          <v-attributes
            v-if="this.$route.query.type == 'person'"
            :person-data="personData"
          />
          <company
            v-if="this.$route.query.type == 'company'"
            :companyData="companyData"
          />
          <div class="items-start flex max-w-full gap-2 mt-4 self-start">
            <div class="">
              <v-button
                @click="goBack()"
                style="padding: 12px 50px"
                :outline="true"
                :text="$t('actions.cancel')"
                class="button_red_outline"
              ></v-button>
            </div>
            <div class="">
              <v-button
                :loader="saveLoader"
                style="padding: 12px 50px"
                class="button_red"
                @click="handleSave()"
                :text="$t('actions.save')"
              >
              </v-button>
            </div>
          </div>
        </article>
      </section>
      <aside
        class="flex flex-col items-stretch w-3/12 max-md:w-full max-md:ml-0"
      >
        <article
          class="hidden items-start border flex w-[364px] max-w-full flex-col px-4 py-5 rounded-xl border-solid border-zinc-300 border-opacity-90 max-md:mt-5"
        >
          <header
            class="text-zinc-800 text-sm font-semibold whitespace-nowrap self-start"
          >
          {{ $t('components.light_ray.upload_your_resume') }}
          </header>

          <div
            class="border bg-neutral-100 flex w-full flex-col mt-4 px-5 py-9 rounded-lg border-dashed border-zinc-300 self-start"
          >
            <div
              class="text-blue-700 text-xs font-medium underline self-center whitespace-nowrap"
            >
              <span class="text-zinc-800">{{ $t('components.light_ray.drag_and_drop_here_or') }}</span
              ><span class="text-blue-700">{{ $t('components.light_ray.upload') }}</span>
            </div>
          </div>
          <div class="items-start flex w-full gap-2 mt-4 self-start">
            <div
              class="border border-[color:var(--theme-color-main,#910000)] bg-white flex flex-col flex-1 px-5 py-3 rounded-lg border-solid"
            >
              <div
                class="text-red-800 text-sm font-medium tracking-wide self-center whitespace-nowrap"
              >
              {{ $t('actions.cancel') }}
              </div>
            </div>
            <div
              class="border-[color:var(--theme-color-main,#910000)] bg-red-800 flex flex-col flex-1 px-5 py-3 rounded-lg border-[0.5px] border-solid"
            >
              <div
                class="text-white text-center text-sm font-medium tracking-wide self-center whitespace-nowrap"
              >
                {{ $t('actions.add') }}
              </div>
            </div>
          </div>
        </article>
      </aside>
    </section>
  </div>
</div>
</template>
<script>
import Attributes from "../../common/attribute";
import group from "../../assets/svg/group.svg";
import company from "../../common/company";
import Button from "../../../components/button";
import { updateManualData, addManualData, deleteManualData } from "../../services";
import Loader from "@/components/loader";
import MainEntity from "../../common/main-entity";



export default {
  name: "add-person",
  components: {
    "v-attributes": Attributes,
    company,
    "v-button": Button,
    "group-icon": group,
    Loader,
    MainEntity
  },
  data() {
    return {
      saveLoader: false,
      main_color: "",
      personData: {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "Not known",
        date_of_birth: "",
      },
      companyData: {
        company_name: "",
        company_number: "",
        inc_date: "",
        date_of_dissolution: "",
        jurisdiction: "",
      },
      showMainEntityMoreList: false,
    };
  },
  created() {
    const rootStyles = getComputedStyle(document.documentElement);
    this.mainColor = rootStyles.getPropertyValue("--theme-color-main");
  },
  mounted() {
    this.$store.dispatch("getEntitiesList");
  },
  computed: {
    isAnyEntityAvailable() {
      return this.$store.getters.getEntitiesList.length > 0;
    },
    entitiesListLoader() {
      return this.$store.getters.getEntitiesListLoader;
    },
    entitiesList() {
      return this.$store.getters.getEntitiesList;
    },
  },
  methods: {
    getCaseFromSessionStorage() {
      return {
        id: sessionStorage.getItem("id"),
        neo_case_id: sessionStorage.getItem("neo_case_id"),
      };
    },
    toggleMoreList(value) {
      this.showMainEntityMoreList = value;
    },
    goBack() {
      if (this.isAnyEntityAvailable) {
        this.$router.push({
          name: "select-features",
          query: {
            package_id: this.$route.query.package_id,
            client_id: this.$route.query.client_id,
            t: new Date().getTime(),
            mode: "features",
          },
        });
      } else {
        this.$router.push({
          name: "add-multientities",
          query: {
            package_id: this.$route.query.package_id,
            client_id: this.$route.query.client_id,
            t: new Date().getTime(),
          },
        });
      }
      this.personData = {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
      };
      this.companyData = {
        company_name: "",
        company_number: "",
        inc_date: "",
        date_of_dissolution: "",
        jurisdiction: "",
      };
    },
    async handleSave() {
      try {
        this.saveLoader = true;
        // const casePayload = { package_id: this.$route.query.package_id };
        let data = this.getCaseFromSessionStorage();
        // if (!data.id || !data.neo_case_id) {
        //   const res = await createCase(casePayload);
        //   data = res.data;
        //   this.saveCaseInSessionStorage(data);
        // }
        let payload = {};
        switch (this.$route.query.type) {
          case "person": {
            if (
              this.personData.first_name.trim() == "" ||
              this.personData.last_name.trim() == ""
            ) {
              this.$toast.error(this.$t('components.light_ray.please_fill_mandatory_fields'));
              this.saveLoader = false;
              return;
            } else {
              payload = {
                case_id: data.neo_case_id,
                entity_type: "person",
                entity_data: {
                  ...this.personData,
                  main_entity: true,
                },
              };
            }
            break;
          }
          case "company": {
            if (
              this.$route.query.type == "company" &&
              this.companyData.company_name.trim() == ""
            ) {
              this.$toast.error(this.$t('components.light_ray.please_add_at_least_company_name'));
              this.saveLoader = false;
              return;
            } else {
              payload = {
                case_id: data.neo_case_id,
                entity_type: "company",
                entity_data: {
                  ...this.companyData,
                  main_entity: true,
                },
              };
            }
            break;
          }
        }

        let saveData;
        if (this.$route.query.mode == "edit") {
          saveData = await updateManualData(this.entity_id, payload);
        } else {
          saveData = await addManualData(payload);
        }
        if (saveData) {
          this.$toast.success(saveData.data.message);
          this.$router.push({
            name: "select-features",
            query: {
              package_id: this.$route.query.package_id,
              client_id: this.$route.query.client_id,
              t: new Date().getTime(),
              mode: "features",
            },
          });
          this.personData = {
            first_name: "",
            middle_name: "",
            last_name: "",
            gender: "",
            date_of_birth: "",
          };
          this.companyData = {
            company_name: "",
            company_number: "",
            inc_date: "",
            date_of_dissolution: "",
            jurisdiction: "",
          };
        }
        this.saveLoader = false;
      } catch (err) {
        console.error(err);
        this.$toast.error(
          err?.response?.data?.detail || this.$t("general.default_error")
        );
      }
    },
    async deleteEntity(entity_id) {
      try {
        let deleteData = await deleteManualData(entity_id);
        if (deleteData) {
          if (this.entitiesList.length == 1) {
            sessionStorage.removeItem("case_name");
            this.$forceUpdate();
            this.$router.push({
              name: "add-multientities",
              query: {
                package_id: this.$route.query.package_id,
                client_id: this.$route.query.client_id,
                t: new Date().getTime(),
              },
            });
          } else {
            this.entitiesList.sort(
              (a, b) => new Date(a.updated_at) - new Date(b.updated_at)
            );
            if (this.entitiesList.length) {
              sessionStorage.setItem(
                "case_name",
                this.entitiesList[0]?.company_name || this.entitiesList[0]?.person
              );
            }
          }
          this.$toast.success(this.$t('components.light_ray.deleted_successfully'));
          await this.$store.dispatch("getEntitiesList");
          // remove the more list option form entity ribbon
          if (this.entitiesList.length < 5) {
            this.showMainEntityMoreList = false;
          }
        } else {
          this.$toast.warning(this.$t('components.light_ray.automated_entity_is_not_deleted'));
        }
      } catch (err) {
        this.$toast.error(this.$t('components.light_ray.entity_is_not_deleted'));
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/color.scss";

.parent {
  // height: 90vh !important;
}
.border_right {
  border-right: 0.25rem solid $lightray_product_color !important;
}
.dynamic_bg {
  background-color: $lightray_product_color !important;
  color: "white";
}
.dynamic_color {
  color: $lightray_product_color !important;
}
.svg_fill_color {
  background: $lightray_product_color !important;
  border: 2px solid $lightray_product_color !important;
}

.svg_outline {
  border: 2px solid $lightray_product_color !important;
}

.custom_scroll::-webkit-scrollbar {
  display: none !important;
}

select {
  height: 18px !important;
}

::v-deep {
  h2 {
    color: black !important;
  }

  input[type="checkbox"]:checked {
    accent-color: $lightray_product_color !important;
    background: $lightray_product_color !important;
  }

  .formulate-input .formulate-input-element {
    max-width: 40em !important;
  }

  .multiselect__tag {
    background: #f2f2f2;
    color: rgb(49, 49, 49);
  }

  .multiselect__tag-icon:hover {
    background: rgb(255, 212, 212);
  }

  .dropdown_parent {
    position: relative;
  }

  .dropdown_content {
    position: absolute;
    top: 100%;
    right: -10px;
  }

  .button_red_outline {
    border-color: $lightray_product_color;
    border-radius: 6px;
    color: $lightray_product_color;
    background-color: white;
  }

  .button_red_outline:hover {
    border-color: $lightray_product_color;
    background-color: $lightray_product_color;
    color: white;
  }

  .button_red {
    border-color: $lightray_product_color;
    background-color: $lightray_product_color;
    color: white;
  }

  //
  .progress {
    height: 3px !important;
  }
  // .progress::-moz-progress-bar {
  //     background-color: $lightray_product_color !important;
  //     animation: progress-anim-1-after 0.5s infinite linear;
  // }

  // .progress-info {
  //     background-color: $lightray_product_color !important;
  // }
}
</style>
