<!-- Localized -->
<template>
  <div>
    <button
      :disabled="disabled"
      @click.stop="openMenu()"
    >
      <Pencil v-if="entityHead && editAble" id="isCanvas" />
      <div v-if="confidence" class="mx-1 label-box" :class="confidenceColor">
        <span>{{ this.individualData?.identifiers.length || "0" }}</span>
      </div>
      <!-- <PencilBold v-else :class="{'hidden': graph}" /> -->
    </button>
    <offCanvas v-if="showMenu" :isOpen="showMenu" position="right">
      <nav
        class="h-16 bg-white border-b-2 p-2 flex justify-between static z-50"
      >
        <div class="w-80 flex items-center">
          <div class="w-10 mr-2">
            <div
              v-if="individualData.entity_identifier == 'name'"
              class="mx-2 w-9 h-9 rounded-full bg-red-200 flex justify-center items-center"
            >
              <User fill="#910000" />
            </div>
            <div
              v-else
              class="mx-2 w-9 h-9 rounded-full bg-red-200 flex justify-center items-center"
            >
              <Company fill="#910000" />
            </div>
          </div>
          <div
            v-show="!editName"
            class="flex justify-between items-center w-full"
            :title="this.individualData?.entity"
          >
            <div
              v-if="
                this.individualData?.source_name == 'judyrecords' &&
                !this.individualData?.entity_meta_data.is_details_scraped
              "
            >
              {{ this.individualData?.entity_meta_data.jurisdiction }}
            </div>
            <div v-else>
              {{
                this.individualData?.entity?.length > 40
                  ? `${this.individualData?.entity?.substr(0, 40)}...`
                  : this.individualData?.entity
              }}
            </div>
            <!-- <div @click="openEditName"> <Edit/></div> -->
          </div>
          <div
            v-show="editName"
            class="border p-2 rounded-md flex justify-between"
          >
            <input
              :placeholder="$t('components.light_ray.enter')"
              type="text"
              :value="this.individualData?.name"
              id="incorporation_date"
            />
            <button class="text-red-600">{{ $t('actions.update') }}</button>
          </div>
        </div>
        <div class="flex justify-between items-center">
          <div class="mr-8">
            <Button
              type="primary"
              class="button_outline px-6 py-2"
              :disabled="this.loadingBtn"
              @click="updateEntity"
              :text="$t('actions.save')"
              :loader="this.loadingBtn"
            ></Button>
            <!-- <button class="button_outline px-6 py-2" @click="updateEntity">Save</button> -->
          </div>
          <div class="flex justify-center items-center">
            <div class="mx-1 label-box" :class="confidenceColor">
              <span>{{ this.individualData?.identifiers.length || "0" }}</span>
            </div>
            <!-- <div :class="this.individualData?.status?.toLowerCase() || 'unknown'"
                            class="mx-2 cursor-pointer w-7 h-7 rounded-full flex justify-center items-center">
                            <MinusIcon
                                v-if="this.individualData?.status?.toLowerCase() == 'unknown' || !this.individualData.status"
                                class="cursor-pointer" />
                            <Check v-else-if="this.individualData?.status?.toLowerCase() == 'confirmed'"
                                class="cursor-pointer" />
                            <Question v-else-if="this.individualData?.status?.toLowerCase() == 'potential'"
                                class="cursor-pointer" />
                            <CrossRed v-else-if="this.individualData?.status?.toLowerCase() == 'irrelvant'"
                                class="cursor-pointer" />
                        </div> -->
            <div class="mx-1 label-box dropdown_parent" :class="this.status">
              <span
                class="label"
                @click="this.toogleDropdown"
                @blur="closeDropdown"
                tabindex="0"
              >
                <Check
                  v-if="this.status == 'confirmed'"
                  class="cursor-pointer"
                />
                <Question
                  v-else-if="this.status == 'potential'"
                  class="cursor-pointer"
                />
                <CrossRed
                  v-else-if="this.status == 'irrelevant'"
                  class="cursor-pointer"
                />
                <MinusIcon v-else class="cursor-pointer" />
              </span>
              <ul
                v-if="showList"
                class="dropdown_content w-36 text-sm font-small bg-white shadow h-auto py-2"
              >
                <li
                  @mousedown="selectDropdown"
                  name="confirmed"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer"
                >
                  <span name="confirmed" class="" style="color: #67b52b"
                    >{{ $t('components.light_ray.confirmed') }}</span
                  >
                  <span
                    name="confirmed"
                    class="badge"
                    style="background: #f0f8ea"
                  >
                    <Check name="confirmed" />
                  </span>
                </li>
                <li
                  @mousedown="selectDropdown"
                  name="potential"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer"
                >
                  <span name="potential" class="" style="color: #fc6713"
                    >{{ $t('components.light_ray.potential') }}</span
                  >
                  <span
                    name="potential"
                    class="badge"
                    style="background: rgba(252, 103, 19, 0.11)"
                  >
                    <Question name="potential" />
                  </span>
                </li>
                <li
                  @mousedown="selectDropdown"
                  name="irrelevant"
                  class="p-2 flex items-center justify-between border-b-2 border-gray-300 cursor-pointer"
                >
                  <span name="irrelevant" class="" style="color: #eb3131"
                    >{{ $t('components.light_ray.irrelevant') }}</span
                  >
                  <span
                    name="irrelevant"
                    class="badge"
                    style="background: #fbeded"
                  >
                    <CrossRed name="irrelevant" />
                  </span>
                </li>
                <li
                  @mousedown="selectDropdown"
                  name="unknown"
                  class="p-2 flex items-center justify-between"
                >
                  <span name="unknown" class="" style="color: #8d8d8d"
                    >{{ $t('components.light_ray.unknown') }}</span
                  >
                  <span
                    name="unknown"
                    class="badge"
                    style="background: #d7d7d7"
                  >
                    <MinusIcon name="unknown" />
                  </span>
                </li>
              </ul>
            </div>
            <div class="">
              <!-- <Threedot /> -->
            </div>
            <div class="mx-2 cursor-pointer" @click="closeMenu">
              <Cross />
            </div>
          </div>
        </div>
      </nav>
      <div class="flex">
        <aside
          class="w-52 h-screen cursor-pointer text-base-content border-r-2"
        >
          <ul>
            <li
              v-for="item in sidebarItems"
              :key="item.id"
              class="pl-3 border-b-2 h-10 hover:bg-red-100"
              @click="selectPage(item)"
              :class="{ 'active_card ': isActive(item) }"
            >
              <div
                class="flex items-center"
                :class="{ 'border-r-4 border-red-700': isActive(item) }"
              >
                <span
                  class="client-card-info self-stretch w-56 text-sm text-black-600 font-small capitalize px-2 py-2"
                >
                  {{ item.name }}
                </span>
              </div>
            </li>
          </ul>
        </aside>
        <div class="p-5 w-full overflow-x-scroll" style="height: 90vh">
          <div v-if="selectedPage === 1">
            <Attribute
              :individualData="getIndividualData"
              :data="attributeData"
            />
          </div>
          <div v-if="selectedPage === 2">
            <Relationship
              :individualData="individualData"
              @setIndividualData="setIndividualData"
              :mainEntity="this.mainEntity"
              :sourceName="sourceName"
            />
          </div>
          <div v-if="selectedPage === 3">
            <Notes :individualData="getIndividualData" />
          </div>
          <div v-if="selectedPage === 4">
            <AssociatedRisk :individualData="getIndividualData" />
          </div>
          <div v-if="selectedPage === 5">
            <Attachments :individualData="getIndividualData" />
          </div>
          <!-- <div v-if="selectedPage === 6">
                        <Monitoring :individualData="getIndividualData" />
                    </div> -->
          <div v-if="selectedPage === 7">
            <Details
              :individualData="getIndividualData"
              :sourceName="sourceName"
              :confidence="this.confidence"
            />
          </div>
        </div>
      </div>
    </offCanvas>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import Button from "../../../components/button";
import Pencil from "../../assets/svg/pencil.svg";
import offCanvas from "../../common/off-canvas";
import User from "../../assets/svg/user.svg";
import Company from "../../assets/svg/company.svg";
import Cross from "../../assets/svg/cross.svg";
import MinusIcon from "@shared/assets/minus-icon.svg";
import Check from "../../assets/svg/check.svg";
import Question from "../../assets/svg/question.svg";
import Attribute from "../Attributes";
import Relationship from "../Relationships";
import Notes from "../Notes";
import AssociatedRisk from "../Associated-Risk";
import Attachments from "../Attachments";
import Details from "../Details";
import { updateEntityDetails, updateInternetDataDetails, updateNodeAttribute } from "../../services";
import CrossRed from "../../assets/svg/cross-red.svg";
import { areDeeplyEqual } from "../../utils";
import { EventBus } from "@/main.js";

export default {
  name: "CanvasMenu",
  components: {
    CrossRed,
    Question,
    Check,
    MinusIcon,
    Pencil,
    // PencilBold,
    offCanvas,
    Button,
    User,
    Company,
    Cross,
    Attribute,
    Relationship,
    Notes,
    AssociatedRisk,
    Attachments,
    // Monitoring,
    Details,
  },
  props: {
    isMainenity: Boolean,
    mainEntity: Array,
    individualData: Object,
    entityHead: Boolean,
    sourceName: String,
    confidence: Boolean,
    isShow: Boolean,
    graph: Boolean,
    disabled: Boolean,
    isEditCanvas: Boolean,
    editAble: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      originalData: {},
      editName: false,
      showMenu: false,
      selectedPage: 7,
      sidebarData: [
        // {
        //     id: 1,
        //     name: "Attributes",
        // },
        {
          id: 2,
          name: "Relationships",
        },
        {
          id: 3,
          name: "Notes",
        },
        {
          id: 4,
          name: "Associated Risks",
        },
        {
          id: 5,
          name: "Attachment",
        },
        // {
        //     id: 6,
        //     name: "Monitoring",
        // },
        {
          id: 7,
          name: "Details",
        },
      ],
      attributeData: {
        companyNumber: "",
        incorporation_date: "",
        dissolution_date: "",
        country: "",
        state: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dob: "",
        gender: "",
        mainEntity: false,
        showGraph: true,
        graphLabel: "",
      },
      loadingBtn: false,
      status: "unknown",
      showList: false,
    };
  },
  created() {
    if (this.individualData?.status != null) {
      this.status = this.individualData.status.toLowerCase() || "unknown";
    }
  },
  methods: {
    ...mapMutations(["SET_NEED_GRAPH_UPDATE"]),

    openEditName() {
      this.editName = !this.editName;
    },
    openMenu(e) {
      if (e && e.stopPropagation) e.stopPropagation();
      if (this.individualData) {
        this.originalData = JSON.parse(JSON.stringify(this.individualData));
      }
      this.showMenu = true;
    },
    closeMenu(e) {
      if (e && e.stopPropagation) e.stopPropagation();
      this.showMenu = false;
      let graph = { graphClose: false, sourceName: this.sourceName };
      this.$emit("updateIsShow", graph);
      let name = {
        opencorporates: 1,
        dnb: 2,
        complyadvantage: 3,
        offshoreleaks: 4,
        judyrecords: 5,
        news: 6,
      };
      let page = name[this.sourceName] || 7;
      if (this.isShow) {
        return;
      }
      if (!areDeeplyEqual(this.originalData, this.individualData)) {
        this.$emit("stateClear", page);
        this.$toast.error(this.$t('components.light_ray.you_havent_clicked_on_save_button_to_save_data'));
      }
      // this.selectedPage = 2;
    },
    openModal(data) {
      this.$emit("openPersonModal", data.entity_type.entity, data);
    },
    selectPage(page) {
      this.selectedPage = page.id;
    },
    isActive(item) {
      return this.selectedPage === item.id;
    },
    setIndividualData(data) {
      this.individualData = data;
    },

    async updateEntity() {
      this.loadingBtn = true;
      try {
        let payload = { ...this.individualData };
        payload = this.individualData;
        delete payload?.checked;
        delete payload?.categories_type;
        delete payload?.name;
        payload.case_id = this.$route.query.case_id;

        if (this.sourceName == "internet") {
          await updateInternetDataDetails(payload, this.individualData?._id);
        } else {
          await updateEntityDetails(payload, this.individualData?._id);
          // Currently is used only to update risks attribute
          await updateNodeAttribute({
            case_id: this.$route.query.case_id,
            node_id: payload.unique_identifier_id,
            params: [{
              key: "risks",
              value: payload.risk_categories,
            }],
          });

          this.SET_NEED_GRAPH_UPDATE();
        }

        this.$toast.success(this.$t('components.light_ray.successfully_updated'));
        EventBus.$emit("getFreshDataAfterSave", null);
        
        // this.closeMenu();
        this.showMenu = false;
        this.loadingBtn = false;
        let name = {
          opencorporates: 1,
          dnb: 2,
          complyadvantage: 3,
          offshoreleaks: 4,
          judyrecords: 5,
          news: 6,
        };
        let page = name[this.sourceName] || 6;
        this.$emit("stateClear", page);
        this.$emit("updateIsShow", false);
      } catch (err) {
        this.loadingBtn = false;
        console.error(err);
        this.$toast.error(
          err?.response?.data?.detail || this.$t("general.default_error")
        );
      }
    },
    toogleDropdown() {
      this.showList = !this.showList;
    },
    closeDropdown() {
      this.showList = false;
    },
    async selectDropdown(event) {
      this.showList = false;
      let key = event.target.innerText;
      this.status = key.toLowerCase();
      this.individualData.status = this.status.toUpperCase();
    },
  },
  mounted() {
    this.selectedPage = 7;
    if (this.isMainenity) {
      this.selectedPage = 2;
    }
    if (this.sourceName == "judyrecords") {
      this.selectedPage = 7;
    }
  },
  computed: {
    sidebarItems() {
      if (this.isMainenity) {
        return this.sidebarData.filter((item) => item.id != 7);
      } else if (this.sourceName == "judyrecords") {
        return this.sidebarData;
      } else if (this.sourceName == "complyadvantage") {
        return this.sidebarData;
      } else if (this.sourceName == "opencorporates") {
        return this.sidebarData;
      } else {
        return this.sidebarData.filter((item) => item.id != 1);
      }
    },
    confidenceColor() {
      return (this.individualData?.confidence_score?.name).toLowerCase();
    },
    getIndividualData() {
      return this.individualData;
    },
  },
  watch: {
    isShow(newVal) {
      if (newVal) {
        if (newVal == true) {
          this.openMenu();
        }
      }
    },
    isEditCanvas(newVal, oldVal) {
      if (newVal || oldVal) {
        this.openMenu();
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "../../assets/scss/color.scss";

.active_card {
  background-color: rgba(145, 0, 0, 0.08);
}

.button_outline {
  border-radius: 8px;
  border: 1px solid $lightray_product_color;
  background: var(--white, #fff);
  color: $lightray_product_color;
  font-size: smaller;
}

.green-box {
  border-radius: 4px;
  background: var(--light-green, #e1f3d3);
  color: var(--complete, #67b52b);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label {
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.label-box {
  display: flex;
  width: 25px;
  height: 25px;
  padding: 3px 7px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
}

.high {
  font-size: 12px;
  font-weight: 400;
  color: #67b52b !important;
  background-color: #e1f3d3;
}

.low {
  font-size: 12px;
  font-weight: 400;
  color: #b52b2b !important;
  background-color: #f3d3d3;
}

.medium {
  font-size: 12px;
  font-weight: 400;
  color: #a7b52b !important;
  background-color: #f3f0d3;
}

.unknown {
  border-radius: 30px;
  background: #d7d7d7;
}

.confirmed {
  border-radius: 30px;
  background: #f0f8ea;
}

.potential {
  border-radius: 30px;
  background: rgba(252, 103, 19, 0.11);
}

.irrelevant {
  border-radius: 30px;
  background: #fbeded;
}

.dropdown_parent {
  position: relative !important;
}

.badge {
  display: flex;
  padding: 4px;
  gap: 10px;
  background-color: white;
  width: 25px;
  height: 25px;
  justify-content: center;
  align-items: center;
  border: 0px;
}

.dropdown_content {
  position: absolute !important;
  top: 120% !important;
  right: 0% !important;
  z-index: 9999 !important;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 2px 2px 11px 0px rgba(68, 82, 90, 0.09);

  li:hover {
    background-color: #d7d7d7 !important;
  }
}
</style>
