<!-- Localized -->
<template>
  <div :class="{ 'flex h-full' : loadingComponentData}">
    <div v-if="!loadingComponentData" >
    <template v-if="entitiesListLoader">
      <div class="flex justify-center items-start">
        <Loader />
      </div>
    </template>
    <template>
      <main-entity
        :main_entity="entitiesList"
        :showMoreList="getShowMainEntityMoreList"
        :key="selectedComponentKey"
        @deleteEntity="deleteEntity"
        @toggleMoreList="toggleMoreList"
      />
    </template>
    <div
      class="h-full bg-white mt-3 border-slate-700 p-4 rounded-2xl parent overflow-y-auto scroll-bar"
      :style="`height: 82vh;`"
      v-if="!featureLoading && isAnyFeatureAvailable && !loadingComponentData"
    >
      <select-query-and-analysis
        
        :selectedEntityComponentData="selectedEntityComponentData"
        :internetSearchData="internetSearchData"
        :analysisData="analysisData"
        :showBuildQuery="showBuildQuery"
        :key="selectedComponentKey"
        :saveEntityDataLoading="saveEntityDataLoader"
        :mainEntitiesData="entitiesList"
        @saveSelectedData="handleSaveSelectedData"
        @nextEntity="nextEntity"
        @previousEntity="previousEntity"
        @clearAll="handleClearAll"
        @submit="saveEntity"
      />
    </div>
  </div>
    <div v-else class="flex justify-center items-center w-full h-full">
        <Loader />
      </div>
  </div>
</template>

<script>
import SelectQueryAndAnalysis from "../../components/select-query-and-analysis";
import MainEntity from "../../common/main-entity";
import Loader from "@/components/loader";

export default {
  name: "select-features",
  components: {
    SelectQueryAndAnalysis,
    MainEntity,
    Loader,
  },
  async mounted() {
    this.loadingComponentData = true
    await this.$store.dispatch("getEntitiesList");
    if (!this.isAnyFeatureAvailable) {
      await this.$store.dispatch("getSelectableFeature");
    }
    await this.manageComponentData();
    await this.$store.dispatch("getSelectedToolState");
    if (!this.entitiesList.length) {
      this.$router.push({
        name: "add-multientities",
        query: {
          package_id: this.$route.query.package_id,
          client_id: this.$route.query.client_id,
          t: new Date().getTime(),
        },
      });
    }
    this.loadingComponentData = false
  },
  data() {
    return {
      main_entity: [],
      selectedEntityId: "",

      selectOutputs: [],
      internetSearch: [],
      analysis: [],
      licenseIds: [],

      loadingComponentData: false,
    };
  },
  computed: {
    entitiesList() {
      return this.$store.getters.getEntitiesList;
    },
    isAnyFeatureAvailable() {
      return this.$store.getters.getIsAnyFeatureAvailable;
    },
    entitiesListLoader() {
      return this.$store.getters.getEntitiesListLoader;
    },
    featureLoading() {
      return this.$store.getters.getFeatureLoader;
    },
    selectedEntityComponentData() {
      return this.$store.getters.getSelectedEntityComponentData;
    },
    internetSearchData() {
      return this.$store.getters.getInternetSearchData;
    },
    analysisData() {
      return this.$store.getters.getAnalysisData;
    },
    showBuildQuery() {
      return this.$store.getters.getShowBuildQuery;
    },
    selectedComponentKey() {
      return this.$store.getters.getSelectedComponentKey;
    },
    saveEntityDataLoader() {
      return this.$store.getters.getSaveEntityDataLoader;
    },
    getShowMainEntityMoreList() {
      return this.$store.getters.showMainEntityMoreList;
    },
  },
  methods: {
    toggleMoreList(value) {
      this.$store.dispatch("toggleMoreList", value);
    },
    getCaseFromSessionStorage() {
      return {
        id: sessionStorage.getItem("id"),
        neo_case_id: sessionStorage.getItem("neo_case_id"),
      };
    },
    // To manage the elect query and output component data
    async manageComponentData() {
      this.$store.dispatch("manageComponentData");
    },
    async handleSaveSelectedData(type, value, id) {
      const payload = { type, value, id };
      // this.$store.dispatch("saveSelectedData", payload);
      await this.$store.dispatch("saveSelectedData", payload);
      // flag to know when we call the api on next
      this.$store.commit("SET_IS_ENTITY_DATA_UPDATED", true);
      // this.isEntityDataUpdated = true;
    },
    async nextEntity() {
      this.$store.dispatch("nextEntity");
    },
    previousEntity() {
      this.$store.dispatch("previousEntity");
    },

    handleClearAll() {
      this.manageComponentData();
    },
    async saveEntity() {
      this.$store.dispatch("saveEntity");
    },
    async deleteEntity(entity_id) {
      this.$store.dispatch("deleteEntity", entity_id);
    },
  },
};
</script>
