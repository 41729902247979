<!-- Localized -->
<template>
  <div class="rounded-lg">
    <h2 class=" head_accordion mt-3 p-2 border rounded-lg" :class="{ 'filter': isFilter }">
      <button @click="toggleAccordion"
        class="group relative flex w-full justify-between items-center rounded-t-[15px] border-0   "
        :aria-expanded="isOpen" :aria-controls="`collapse${_uid}`">
        <div class="flex justify-between w-full pr-1">
          <slot name="title" />
          <slot class="" name="titleCheckbox" />
        </div>
        <span>
          <svg class="w-3 transition-all duration-200 transform" :class="{
            'rotate-180': isOpen,
            'rotate-0': !isOpen,
          }" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 10"
            aria-hidden="true">
            <path d="M15 1.2l-7 7-7-7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
        </span>
      </button>
    </h2>
    <div v-show="isOpen" :id="`collapse${_uid}`">
      <slot name="content" />
    </div>
  </div>
</template>
  
<script>
export default {
  data() {
    return {
      isOpen: true,
    };
  },
  props: {
    isFilter: { type: Boolean, default: false }
  },
  mounted() {
    if (this.isFilter) {
      this.isOpen = false
    }
  },
  methods: {
    toggleAccordion(e) {
      if (e.target.id !== "checkboxInput") {
        this.isOpen = !this.isOpen;
        // e.stopPropogation();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.head_accordion {
  background-color: #bfe0f5 !important;
}

.filter {
  background: transparent !important;
  border: none;
  border-bottom-color: currentcolor;
  border-bottom-style: none;
  border-bottom: 1px solid rgba(60, 60, 60, 0.5);
  border-radius: 0px;
}
</style>