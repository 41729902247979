<!-- Localized -->
<template>
    <div class="mx-2 " :class="this.expand ? 'grid grid-cols-1 gap-4' : 'grid grid-cols-1 gap-4'">
        <div class="w-auto" v-for="(item, indx)  in checkedCaseData" :key="indx">
            <PepCard :individualData="item"  :mainEntity="mainEntity" :sourceName="sourceName"
            @stateClear="updateSelectedPage" @updateFilterState="updateFilterState" />
        </div>
    </div>
</template>
<script>
import PepCard from '../../common/pep-card'
export default {
    components: {
        PepCard,
    },
    props: {
        mainEntity:Array,
        caseData: Array,
        expand: Boolean,
        sourceName:String
    },
    methods: {
        updateSelectedPage(page){
            this.$emit('stateClear', page);
        },
        updateFilterState(){
        this.$emit("getGlobalFiltersData");
        // this.$emit("pageUpdated");
      }

    },
    computed: {
        checkedCaseData() {
            return this.caseData
        },
 
    },
};
</script>
    
<style></style>