<!-- Localized -->
<template>
  <div class="flex w-full flex-col">
    <div class="flex w-full items-center justify-center">
      <div class="flex p-4 justify-center items-center">
        <div class="font-semibold text-md">{{ $t('components.light_ray.select_queries_and_analysis') }}</div>
        <div class="px-4">
          <CircleInfo />
        </div>
      </div>
    </div>
    <div class="flex py-2" v-if="showEntityList">
      <main-entity
        :main_entity="mainEntitiesData"
        :showMoreList="showMainEntityMoreList"
        :fromSelectQueries="true"
        :fromRunIntelligence="fromRunIntelligence"
        @deleteEntity="deleteEntity"
        @toggleMoreList="toggleMoreList"
      />
    </div>
    <div class="flex-col">
      <!-- Select Outputs -->
      <div
        v-if="getSelectedEntityData.length"
        class="flex-col pb-6 border-b-2 border-gray-200"
      >
        <div class="flex py-4">
          <span class="text-md font-semibold">{{ $t('components.light_ray.select_outputs') }}</span>
        </div>
        <div class="flex overflow-hidden flex-wrap gap-x-8 gap-y-4">
          <div v-for="items in getSelectedEntityData" :key="items.id">
            <CheckBoxCardVue
              :items="items"
              @input="handleInput"
              type="selectOutputs"
              :fromRunIntelligence="fromRunIntelligence"
              :runIntelligenceRun="isRunIntelligenceRun"
            />
          </div>
        </div>
      </div>
      <div v-else class="flex h-full w-full items-center justify-center">
        <div>{{ $t('components.light_ray.no_feature_found') }}</div>
      </div>
      <!-- Internet Search -->
      <div class="flex-col pb-6 border-b-2 border-gray-200">
        <div class="flex py-4">
          <span class="text-md font-semibold">{{ $t('components.light_ray.internet_search') }}</span>
        </div>
        <div
          v-if="getInternetSearchData.length"
          class="flex overflow-hidden flex-wrap gap-x-8 gap-y-4"
        >
          <div v-for="items in getInternetSearchData" :key="items.id">
            <CheckBoxCardVue
              :items="items"
              @input="handleInput"
              type="internetSearch"
              :fromRunIntelligence="fromRunIntelligence"
              :runIntelligenceRun="isRunIntelligenceRun"
            />
          </div>
        </div>
        <div v-else class="flex h-full w-full items-center justify-center">
          <div>{{ $t('components.light_ray.no_internet_feature_found') }}</div>
        </div>
      </div>
      <!-- Analysis -->
      <div class="flex-col pb-6 border-b-2 border-gray-200">
        <div class="flex py-4">
          <span class="text-md font-semibold">{{ $t('components.light_ray.analysis') }}</span>
        </div>
        <div
          v-if="getAnalysisData.length"
          class="flex overflow-hidden flex-wrap gap-x-8 gap-y-4"
        >
          <div v-for="items in getAnalysisData" :key="items.id">
            <RadioButtonCard
              :items="items"
              @input="handleInput"
              type="analysis"
            />
          </div>
        </div>
        <div v-else class="flex h-full w-full items-center justify-center">
          <div>{{ $t('components.light_ray.no_analysis_tool_found') }}</div>
        </div>
      </div>
    </div>
    <div class="flex justify-end px-6">
      <div class="flex pt-6 gap-6">
        <button
          type="button"
          class="font-medium rounded-lg text-sm px-5 h-10"
          :class="{
            'opacity-50 cursor-not-allowed': clearAllButtonDisabled,
            'text-gray-500': clearAllButtonDisabled,
            'hover:bg-blue-100 text-blue-700 ': !clearAllButtonDisabled,
          }"
          :disabled="clearAllButtonDisabled"
          @click="clearAll"
        >
        {{ $t('components.light_ray.clear_all') }}
        </button>
        <button
          type="button"
          class="text-blue-700 border-2 border-blue-700 font-medium rounded-lg text-sm px-5 h-10"
          :disabled="backButtonDisabled"
          :class="{
            'opacity-50 cursor-not-allowed bg-gray-300': backButtonDisabled,
            'hover:bg-blue-100': !backButtonDisabled,
          }"
          @click="previousEntity"
        >
        {{ $t('components.light_ray.back') }}
        </button>
        <button
          v-if="!showBuildQuery"
          type="button"
          :disabled="getLoading"
          :class="{ 'opacity-50 cursor-not-allowed': getLoading }"
          class="text-white font-medium rounded-lg text-sm px-5 h-10 bg-blue-700 hover:bg-blue-800"
          @click="nextEntity"
        >
          <span v-if="getLoading">
            <svg
              aria-hidden="true"
              role="status"
              class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              ></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#1C64F2"
              ></path>
            </svg>
          </span>
          <span class="px-1">{{ $t('components.light_ray.next') }}</span>
        </button>
        <button
          v-else
          type="button"
          :disabled="saveEntityLoader"
          :class="{ 'opacity-50 cursor-not-allowed': saveEntityLoader }"
          class="text-white bg-blue-700 hover:bg-blue-800 font-medium rounded-lg text-sm px-5 h-10"
          @click="submit"
        >
        <span v-if="saveEntityLoader">
            <svg
              aria-hidden="true"
              role="status"
              class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              ></path>
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="#1C64F2"
              ></path>
            </svg>
          </span>
          <span class="px-1">{{ $t('components.light_ray.submit') }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CheckBoxCardVue from "./CheckBoxCard.vue";
import RadioButtonCard from "./RadioButtonCard.vue";
import MainEntity from "../../common/main-entity";
import CircleInfo from "../../assets/svg/circle_info.svg";

export default {
  name: "select-query-and-analysis",
  props: {
    selectedEntityComponentData: {
      type: Array,
      default: () => [],
    },
    internetSearchData: {
      type: Array,
      default: () => [],
    },
    analysisData: {
      type: Array,
      default: () => [],
    },
    showBuildQuery: {
      type: Boolean,
      default: false,
    },
    saveEntityDataLoading: {
      type: Boolean,
    },
    mainEntitiesData: {
      type: Array,
      default: () => [],
    },
    showMainEntityMoreList: {
      type: Boolean,
      default: false,
    },
    showEntityList: {
      type: Boolean,
      default: false,
    },
    fromRunIntelligence: {
      type: Boolean,
      default: false,
    },
    isRunIntelligenceRun: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CheckBoxCardVue,
    RadioButtonCard,
    MainEntity,
    CircleInfo,
  },
  computed: {
    getSelectedEntityData() {
      return this.selectedEntityComponentData;
    },
    getInternetSearchData() {
      return this.internetSearchData;
    },
    getAnalysisData() {
      return this.analysisData;
    },
    getLoading() {
      return this.saveEntityDataLoading;
    },
    backButtonDisabled() {
      return this.mainEntitiesData.length
        ? this.mainEntitiesData[0].selected
        : false;
    },
    clearAllButtonDisabled() {
      return this.$store.getters.getClearAllButtonDisabled;
    },
    saveEntityLoader() {
      return this.$store.getters.getSaveEntityDataLoader;
    }
  },
  data() {
    return {
      selectOutputs: [
        {
          id: "openCorporates",
          value: "openCorporates",
          label: "Open Corporates",
          checked: false,
        },
        {
          id: "companyCredits",
          value: "companyCredits",
          label: "Company Credits",
          checked: false,
        },
        {
          id: "adversePepSenctions",
          value: "adversePepSenctions",
          label: "Adverse/PEPs/Sanctions",
          checked: false,
        },
        {
          id: "offShoreRecords",
          value: "offShoreRecords",
          label: "Offshore Records",
          checked: false,
        },
        {
          id: "courtRecords",
          value: "courtRecords",
          label: "Court Records",
          checked: false,
        },
        { id: "news", value: "news", label: "News", checked: false },
      ],
      internetSearch: [
        {
          id: "criminal",
          value: "criminal",
          label: "Criminal",
          checked: false,
        },
        {
          id: "sexualAssault",
          value: "sexualAssault",
          label: "Sexual Assault",
          checked: false,
        },
        {
          id: "harassment",
          value: "harassment",
          label: "Harassment",
          checked: false,
        },
        { id: "scandal", value: "scandal", label: "Scandal", checked: false },
      ],
      loader: false,
    };
  },
  mounted() {},
  methods: {
    nextEntity() {
      this.$emit("nextEntity", null);
    },
    previousEntity() {
      this.$emit("previousEntity", null);
    },
    clearAll() {
      this.$emit("clearAll", null);
    },
    submit() {
      this.$emit("submit", null);
    },
    handleInput({ value, id, type }) {
      switch (type) {
        case "selectOutputs": {
          this.$emit("saveSelectedData", type, value, id);
          break;
        }
        case "internetSearch": {
          this.$emit("saveSelectedData", type, value, id);
          break;
        }
        case "analysis": {
          this.$emit("saveSelectedData", type, value, id);
          break;
        }
      }
    },
    toggleMoreList(value) {
      this.$emit("toggleMoreList", value);
      // this.showMainEntityMoreList = value;
    },
    deleteEntity(id) {
      this.$emit("deleteEntity", id);
    },
  },
};
</script>
