<!-- Localized -->
<template>
    <div class="card shadow rounded-md p-2" ref="internet">
        <div :data-attribute="this.matching">
            <span class="font-semibold"><span class="text-gray-400">{{ $t('components.light_ray.title_') }}</span> {{ adverseCard?.title }}</span>
            <div class=" text-left py-2 pr-3 ">
                <span class="text-gray-400">{{ $t('components.light_ray.snippet_') }}</span> {{ adverseCard?.snippet }}
            </div>
            <p class="">
                <span class="text-gray-400">{{ $t('components.light_ray.description') }}</span>
                {{ adverseCard?.article?.article_body ? adverseCard?.article?.article_body : 'Description not available' }}
            </p>
        </div>
    </div>
</template>
<script>
import { get } from "lodash";
// import { DateTime } from "luxon";
import printDataMixin from "@shared/mixins/printDataMixin";
export default {
    components: {

    },
    data() {
        return {
            matching: Array,
            isPrintLoader: false,
        }
    },
    props: {
        identifiers: Array,
        adverseCard: Object,
    },
    mounted() {
    },
    updated() {
        const news = this.$refs.internet;
        if (news) {
      const elements = news.querySelectorAll("[data-attribute]");
      if(elements.length){
      elements.forEach((element) => {
        const innerHtml = element.innerHTML;
        let attributeValue = element.getAttribute("data-attribute");
        if(attributeValue.length){
        attributeValue =  attributeValue.split(",");
        let highlightedHtml = innerHtml;
        attributeValue.forEach((att) => {
          highlightedHtml = this.highlightText(highlightedHtml, att);
        });
        element.innerHTML = highlightedHtml;
      }
      });
    }
  }
    },
    created() {
        this.matching = this.identifiers.map(i => i.entity);

    },
    mixins: [printDataMixin],
    methods: {
        get,
        highlightText(text, query) {
            const regex = new RegExp(`(${query})`, "gi");
            return text.replace(regex, (match) => {
                return `<span class='highlighted badge badge-warning'>${match}</span>`;
            });
        },
    }
};

</script>
<style class="scss" scoped></style>